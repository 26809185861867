import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-action-user',
  templateUrl: './action-user.component.html',
  styleUrls: ['./action-user.component.css']
})
export class ActionUserComponent {
  constructor() { }

  ngOnInit(): void {
  }

  public cellValue!: any;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = params;

  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = "";
    return true;
  }

  deleteEvent() {
    this.cellValue.parent.actionDelete(this.cellValue.data.id)
  }

  editAccount(id: null){
    this.cellValue.parent.openModalUpdate(id, this.cellValue.data)
  }
  changedPassword(){
    this.cellValue.parent.actionUpdatePassword(this.cellValue.data.id)
  }

  deleteAccount(id: null){
    this.cellValue.parent.deleteAccount(id, this.cellValue.data)
  }
  uploadPhoto(id: any){
    this.cellValue.parent.modalUploadPhoto(id)
  }
}
