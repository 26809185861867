<div class="row" style="height: 65vh;">
    <div class="col-lg-6 h-100">
        <div class="card card-border-shadow-primary h-100">
            <div class="card-body h-100" style="overflow: hidden;padding-bottom: 4rem;">
                <h4 class="card-title">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.5 2.5H12.7727C16.0339 2.5 17.6645 2.5 18.7969 3.29784C19.1214 3.52643 19.4094 3.79752 19.6523 4.10289C20.5 5.16867 20.5 6.70336 20.5 9.77273V12.3182C20.5 15.2814 20.5 16.7629 20.0311 17.9462C19.2772 19.8486 17.6829 21.3491 15.6616 22.0586C14.4044 22.5 12.8302 22.5 9.68182 22.5C7.88275 22.5 6.98322 22.5 6.26478 22.2478C5.10979 21.8424 4.19875 20.9849 3.76796 19.8979C3.5 19.2217 3.5 18.3751 3.5 16.6818V12.5"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M20.5 12.5C20.5 14.3409 19.0076 15.8333 17.1667 15.8333C16.5009 15.8333 15.716 15.7167 15.0686 15.8901C14.4935 16.0442 14.0442 16.4935 13.8901 17.0686C13.7167 17.716 13.8333 18.5009 13.8333 19.1667C13.8333 21.0076 12.3409 22.5 10.5 22.5"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M4.5 8C4.99153 8.5057 6.29977 10.5 7 10.5M9.5 8C9.00847 8.5057 7.70023 10.5 7 10.5M7 10.5L7 2.5"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    Evidencias
                </h4>
                <div class="d-grid gap-1 col-lg-12 mx-auto h-100" style="overflow: hidden; overflow-y: auto;">
                    <p *ngIf="order.status_id < 3" class="text-center mt-4">Podrás subir tus evidencias a medida que
                        avances en el proceso.</p>
                    <a style="text-decoration: underline; color: #00A0E1; cursor: pointer;"
                        *ngIf="(order.status_id == 3 && !evidence_1  ) && dataUser.type_admin != 4 "
                        (click)="modalActiveEvidence()" class="card-link">Subir evidencias de carga</a>

                    <ul *ngIf="evidence_1" style="padding-left: 1.2rem;">
                        <li style="color: #00A0E1; margin-left: 0;">
                            Evidencias de Carga
                            <span style="float: right; padding-right: .5rem; cursor: pointer;"
                                (click)="modalOpenEvidence(3)">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.67308 4.5C4.49457 4.5 4.32337 4.57091 4.19714 4.69714C4.07091 4.82337 4 4.99457 4 5.17308V15.8462C4 16.2604 3.66421 16.5962 3.25 16.5962C2.83579 16.5962 2.5 16.2604 2.5 15.8462V5.17308C2.5 4.59674 2.72895 4.04401 3.13648 3.63648C3.54401 3.22895 4.09674 3 4.67308 3H17.4808C17.895 3 18.2308 3.33579 18.2308 3.75C18.2308 4.16421 17.895 4.5 17.4808 4.5H4.67308Z"
                                        fill="#00A0E1" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M21.4242 19.8568C22.0678 19.4796 22.5 18.7806 22.5 17.9807V8.73073C22.5 7.53057 21.5271 6.55765 20.3269 6.55765H8.23077C7.03061 6.55765 6.05769 7.53057 6.05769 8.73073V17.9807C6.05769 18.839 6.55531 19.5811 7.27778 19.9342C7.33326 19.9674 7.39202 19.9927 7.45252 20.0103C7.69414 20.103 7.95652 20.1538 8.23077 20.1538H20.3269C20.6266 20.1538 20.9121 20.0932 21.1718 19.9835C21.2613 19.9583 21.3471 19.9161 21.4242 19.8568ZM20.9706 18.178C20.9897 18.1156 21 18.0494 21 17.9807V8.73073C21 8.359 20.6987 8.05765 20.3269 8.05765H8.23077C7.85904 8.05765 7.55769 8.359 7.55769 8.73073V17.9807C7.55769 18.0766 7.57772 18.1677 7.61382 18.2503L12.7056 12.8734C12.9066 12.6543 13.1504 12.4787 13.4219 12.3575C13.696 12.2352 13.9924 12.171 14.2925 12.169C14.5927 12.167 14.8899 12.2271 15.1656 12.3456C15.4401 12.4636 15.6873 12.637 15.8917 12.8547L20.9706 18.178ZM19.3514 18.6538L14.7989 13.8823C14.7354 13.8144 14.6586 13.7604 14.5732 13.7237C14.4878 13.687 14.3957 13.6683 14.3028 13.669C14.2098 13.6696 14.118 13.6895 14.0331 13.7273C13.9482 13.7652 13.8721 13.8203 13.8095 13.889L13.7994 13.8999L9.29752 18.6538H19.3514Z"
                                        fill="#00A0E1" />
                                </svg>

                            </span>
                        </li>
                    </ul>
                    <div *ngIf="evidence_1" class="row" style="padding-bottom: 1rem;">
                        <div class="d-flex justify-content-center" *ngIf="finishEvidence == false">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-1" *ngFor="let item of filterEvidences(3)">
                            <img src="{{url_page}}{{item.img}}" alt="" class="img-thumbnail">
                        </div>
                    </div>

                    <a style="text-decoration: underline; color: #00A0E1; cursor: pointer;"
                        *ngIf="(order.status_id == 4 && !evidence_2 ) && dataUser.type_admin != 4"
                        (click)="modalActiveEvidence()" class="card-link">
                        Subir Evidencias de Descarga
                    </a>
                    <ul *ngIf="evidence_2" style="padding-left: 1.2rem;">
                        <li style="color: #00A0E1; margin-left: 0;">
                            Evidencias de Descarga
                            <span style="float: right; padding-right: .5rem; cursor: pointer;"
                                (click)="modalOpenEvidence(4)">
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4.67308 4.5C4.49457 4.5 4.32337 4.57091 4.19714 4.69714C4.07091 4.82337 4 4.99457 4 5.17308V15.8462C4 16.2604 3.66421 16.5962 3.25 16.5962C2.83579 16.5962 2.5 16.2604 2.5 15.8462V5.17308C2.5 4.59674 2.72895 4.04401 3.13648 3.63648C3.54401 3.22895 4.09674 3 4.67308 3H17.4808C17.895 3 18.2308 3.33579 18.2308 3.75C18.2308 4.16421 17.895 4.5 17.4808 4.5H4.67308Z"
                                        fill="#00A0E1" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M21.4242 19.8568C22.0678 19.4796 22.5 18.7806 22.5 17.9807V8.73073C22.5 7.53057 21.5271 6.55765 20.3269 6.55765H8.23077C7.03061 6.55765 6.05769 7.53057 6.05769 8.73073V17.9807C6.05769 18.839 6.55531 19.5811 7.27778 19.9342C7.33326 19.9674 7.39202 19.9927 7.45252 20.0103C7.69414 20.103 7.95652 20.1538 8.23077 20.1538H20.3269C20.6266 20.1538 20.9121 20.0932 21.1718 19.9835C21.2613 19.9583 21.3471 19.9161 21.4242 19.8568ZM20.9706 18.178C20.9897 18.1156 21 18.0494 21 17.9807V8.73073C21 8.359 20.6987 8.05765 20.3269 8.05765H8.23077C7.85904 8.05765 7.55769 8.359 7.55769 8.73073V17.9807C7.55769 18.0766 7.57772 18.1677 7.61382 18.2503L12.7056 12.8734C12.9066 12.6543 13.1504 12.4787 13.4219 12.3575C13.696 12.2352 13.9924 12.171 14.2925 12.169C14.5927 12.167 14.8899 12.2271 15.1656 12.3456C15.4401 12.4636 15.6873 12.637 15.8917 12.8547L20.9706 18.178ZM19.3514 18.6538L14.7989 13.8823C14.7354 13.8144 14.6586 13.7604 14.5732 13.7237C14.4878 13.687 14.3957 13.6683 14.3028 13.669C14.2098 13.6696 14.118 13.6895 14.0331 13.7273C13.9482 13.7652 13.8721 13.8203 13.8095 13.889L13.7994 13.8999L9.29752 18.6538H19.3514Z"
                                        fill="#00A0E1" />
                                </svg>

                            </span>
                        </li>
                    </ul>
                    <div *ngIf="evidence_2" class="row">
                        <div class="d-flex justify-content-center" *ngIf="finishEvidence == false">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="col-lg-3 mb-1" *ngFor="let item of filterEvidences(4)">
                            <img src="{{url_page}}{{item.img}}" alt="" class="img-thumbnail">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-6 h-100">
        <div class="card card-border-shadow-primary h-100">
            <div class="card-body h-90" style="height: 80% !important;">
                <h4 class="card-title">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M13.0294 2C17.0225 2 19.019 2 20.2595 3.17157C21.5 4.34315 21.5 6.22876 21.5 10V14C21.5 17.7712 21.5 19.6569 20.2595 20.8284C19.019 22 17.0225 22 13.0294 22H11.9706C7.97751 22 5.98098 22 4.74049 20.8284C3.5 19.6569 3.5 17.7712 3.5 14L3.5 10C3.5 6.22876 3.5 4.34315 4.74049 3.17157C5.98098 2 7.97752 2 11.9706 2L13.0294 2Z"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M8.5 7H16.5" stroke="#545454" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M8.5 12H16.5" stroke="#545454" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M8.5 17H12.5" stroke="#545454" stroke-width="1.5" stroke-linecap="round" />
                    </svg>

                    Información del servicio
                </h4>
                <div class="d-grid gap-1 col-lg-12 mx-auto h-100">
                    <div class="h-35" style="height: 15vh;">
                        <ul style="padding-left: 1rem;">
                            <li style="color: #00A0E1; margin-left: 0;">Notas</li>
                        </ul>
                        <div class="row">
                            <div class="col-lg-12">
                                <textarea (focusout)="observationUpdate($event)" class="form-control"
                                    [placeholder]="order.observation == null ? 'No hay notas para este servicio' : order.observation"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="h-65" style="height: 85%; overflow: hidden; overflow-y: auto;">
                        <ul style="padding-left: 1.2rem;">
                            <li style="color: #00A0E1; margin-left: 0;">Historial de actualización</li>
                        </ul>
                        <div class="row" *ngFor="let item of logs; let i = index">
                            <div class="d-flex justify-content-center" *ngIf="finishLog == false">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <div class="col-lg-6 mb-3">
                                <p class="mb-0 font-weight-bold" style="color: #545454;">Ultima actualización:</p>
                                <ul class="mb-0">
                                    <li>
                                        <b class="" style="color: #9E9A9C">Fecha y hora: </b><span class=""
                                            style="color: #9E9A9C">{{item.created_at |
                                            date:'dd/MM/yyyy, HH:mm'}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-lg-6 mb-3">
                                <p class="mb-0 font-weight-bold" style="color: #545454;">Realizado por:</p>
                                <ul class="mb-0">
                                    <li><span class="" style="color: #9E9A9C">{{item.name}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body text-center">
                <div class="row">
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-outline-primary btn-block"
                            style="width: 100%; letter-spacing: 0 p !important;" (click)="cancelOrder()">Cancelar
                            servicio</button>
                    </div>
                    <div class="col-lg-6">
                        <button type="button" class="btn btn-primary btn-block"
                            style="width: 100%; letter-spacing: 0 !important;" (click)="dowloandService()">Descargar
                            solicitud(PDF)</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row mt-4">
    <div class="col-lg-6">
        <div class="card card-border-shadow-primary h-100">
            <div class="card-body h-100" style="overflow: hidden;padding-bottom: 4rem;">
                <h4 class="card-title">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17" cy="18" r="2" stroke="#545454" stroke-width="1.5" />
                        <circle cx="7" cy="18" r="2" stroke="#545454" stroke-width="1.5" />
                        <path
                            d="M11 17H15M13.5 7H14.4429C15.7533 7 16.4086 7 16.9641 7.31452C17.5196 7.62904 17.89 8.20972 18.6308 9.37107C19.1502 10.1854 19.6955 10.7765 20.4622 11.3024C21.2341 11.8318 21.6012 12.0906 21.8049 12.506C22 12.9038 22 13.375 22 14.3173C22 15.5596 22 16.1808 21.651 16.5755C21.636 16.5925 21.6207 16.609 21.6049 16.625C21.2375 17 20.6594 17 19.503 17H19"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M13 7L13.9942 9.48556C14.4813 10.7034 14.7249 11.3123 15.2328 11.6561C15.7407 12 16.3965 12 17.7081 12H21"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M4.86957 17C3.51684 17 2.84048 17 2.42024 16.5607C2 16.1213 2 15.4142 2 14V7C2 5.58579 2 4.87868 2.42024 4.43934C2.84048 4 3.51684 4 4.86957 4H10.1304C11.4832 4 12.1595 4 12.5798 4.43934C13 4.87868 13 5.58579 13 7V17H8.69565"
                            stroke="#545454" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                    Unidad
                </h4>
                <div class="d-grid gap-1 col-lg-12 mx-auto h-100" style="overflow: hidden; overflow-y: auto;">
                    <a style="text-decoration: underline; color: #00A0E1; cursor: pointer;"
                        (click)="changedUnit()" class="card-link">
                        Cambio de unidad
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalEvidence" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Adjuntar evidencias</h3>
                </div>

                <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label>Seleccione las evidencias</ngx-dropzone-label>

                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label></ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>

                <div class="text-center mt-4">
                    <button class="btn btn-primary" [disabled]="!files.length" (click)="uploadEvidences()">Cargar
                        evidencias</button>
                    <!-- <p class="text-muted">Diligencie los siguentes datos</p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalEvidenceShow" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Evidencias de {{type_text}}</h3>
                </div>
                <div class="row">
                    <div id="carouselExample" class="carousel slide">
                        <div class="carousel-inner">
                            <div class="carousel-item {{i == 0 ? 'active': ''}}"
                                *ngFor="let item of evidences; let i = index">
                                <img src="{{url_page}}{{item.img}}" class="d-block w-100"
                                    alt="Evidencias de {{type_text}}">
                            </div>
                            <!-- <div class="carousel-item">
                                <img src="..." class="d-block w-100" alt="...">
                            </div> -->
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>

                    <!-- <div class="col-lg-4 col-md-12 mb-4 mb-lg-0" *ngFor="let item of evidences">
                        <img src="{{url_page}}{{item.img}}" class="w-100 shadow-1-strong rounded mb-4"
                            alt="Evidencias de carga tmh logistica" />
                    </div> -->


                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">{{'Espere un momento...'}}</p>
</ngx-spinner>