import { Component } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionsComponent } from 'src/app/cell-renderer/actions/actions.component';
import { LoadingGridComponent } from 'src/app/cell-renderer/loading-grid/loading-grid.component';
import { UnitService } from 'src/app/services/unit.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-unit-list',
    templateUrl: './unit-list.component.html',
    styleUrls: ['./unit-list.component.css']
})
export class UnitListComponent {
    constructor(private apiUnit: UnitService, private utils: UtilsService, private spinner: NgxSpinnerService) { }
    address: any = []

    columnDefs = [
        { headerName: "Accion", width: 95, cellRenderer: ActionsComponent, cellRendererParams: { parent: this, url_edit: "/unit/update/" } },

        { field: 'unit', headerName: "Unidad" },
        { field: 'plates', headerName: "Placas" }
    ];

    public loadingCellRenderer: any = LoadingGridComponent;
    public loadingCellRendererParams: any = {
        loadingMessage: 'Espere un momento...',
    };

    public defaultColDef = {
        sortable: true,
        filter: true,
    };

    private gridApi!: GridApi;

    public rowData: any = [];

    ngOnInit(): void {
    }

    event_text = ''

    onGridReady(params: any) {
        this.spinner.show()
        this.apiUnit.listUnit().subscribe((resp) => {
            this.rowData = resp;
            this.spinner.hide()
        })
    }
    actionDelete(id: number) {
        this.utils.alertSwalConfir({title:"Eliminación", text:"Esta seguro que desea eliminar la unidad seleccionada", icon: "warning", btn_confirm: "Elimniar"})
        .then((result) => {
            if(result.isConfirmed){
                this.spinner.show()
                this.apiUnit.deleteUnit(id).subscribe((resp) => {
                    this.spinner.hide()
                    this.utils.alertSwal({title: "Operación Exitosa", text: "Se ha realizado la eliminacion de la unidad de forma exitosa", icon: "success"})
                    this.onGridReady({})
                }, (error) => {
                    this.spinner.hide()
                    this.utils.alertSwal({title: "Error", text: "Se ha presentado un error al intentar eliminar la unidad", icon: "error"})
                })
                
            }
        })
    }
}
