<div class="col-xl">
    <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
            <a class="btn btn-primary" (click)="openModalAdd()" style="color: #fff;"><i
                    class="fas fa-regular fa-circle-plus add-address"></i>&nbsp;&nbsp;
                Agregar Cuenta</a>
        </div>
        <div class="card-body">
            <ag-grid-angular style="width: 100%; height: 60vh;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [animateRows]="true"
                [loadingCellRenderer]="loadingCellRenderer" [loadingCellRendererParams]="loadingCellRendererParams"
                (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
    </div>
</div>


<div class="modal fade" id="modalAddAccount" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2"></h3>
                </div>

                <div class="col-xl">
                    <form [formGroup]="accountForm" id="formAccount" (ngSubmit)="addAccount()">
                        <div class="card mb-4">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h5 class="mb-0">Creación Cuenta de Usuario</h5>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Nombre*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.name"
                                                    [ngClass]="accountForm.controls['name'].errors  && !accountForm.controls['name'].pristine ? 'is-invalid' : ''"
                                                    formControlName="name">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['name'].errors  && !accountForm.controls['name'].pristine">
                                                    El campo nombre es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Usuario*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.user"
                                                    [ngClass]="accountForm.controls['user'].errors  && !accountForm.controls['user'].pristine ? 'is-invalid' : ''"
                                                    formControlName="user">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['user'].errors  && !accountForm.controls['user'].pristine">
                                                    El campo usuario es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Correo
                                                Electronico*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.email"
                                                    [ngClass]="accountForm.controls['email'].errors  && !accountForm.controls['email'].pristine ? 'is-invalid' : ''"
                                                    formControlName="email">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['email'].errors  && !accountForm.controls['email'].pristine">
                                                    El campo correo electronico es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Tipo de
                                                Usuario*</label>
                                            <div class="input-group input-group-merge">
                                                <select class="form-control" [(ngModel)]="formModelData.type_admin"
                                                    [ngClass]="accountForm.controls['type_admin'].errors  && !accountForm.controls['type_admin'].pristine ? 'is-invalid' : ''"
                                                    formControlName="type_admin">
                                                    <option value="">-- Seleccione --</option>
                                                    <option value="1">Administrador</option>
                                                    <option value="2">Director</option>
                                                    <option value="3">Coordinador</option>
                                                    <option value="4">Supervisor</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['type_admin'].errors  && !accountForm.controls['type_admin'].pristine">
                                                    El campo tipo de usuario es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Contraseña*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.password"
                                                    [ngClass]="accountForm.controls['password'].errors  && !accountForm.controls['password'].pristine ? 'is-invalid' : ''"
                                                    formControlName="password">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['password'].errors  && !accountForm.controls['password'].pristine">
                                                    El campo usuario es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Estado*</label>
                                            <div class="input-group input-group-merge">
                                                <label class="switch switch-lg">
                                                    <input type="checkbox" id="addAccountStatus" class="switch-input" [checked]="formModelData.status" (change)="changedStatus('addAccountStatus')" />
                                                    <span class="switch-toggle-slider">
                                                        <span class="switch-on">
                                                            <i class="ti ti-check"></i>
                                                        </span>
                                                        <span class="switch-off">
                                                            <i class="ti ti-x"></i>
                                                        </span>
                                                    </span>
                                                    <span class="switch-label">{{!formModelData.status ? 'Inactivo' : 'Activo'}}</span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-body text-center">
                                <button type="submit" class="btn btn-primary me-sm-3 me-1">Crear Cuenta</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalUpdateAccount" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2"></h3>
                </div>

                <div class="col-xl">
                    <form [formGroup]="accountForm" id="formAccount" (ngSubmit)="updateAccount()">
                        <div class="card mb-4">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h5 class="mb-0">Actualización Cuenta de Usuario</h5>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Nombre*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.name"
                                                    [ngClass]="accountForm.controls['name'].errors  && !accountForm.controls['name'].pristine ? 'is-invalid' : ''"
                                                    formControlName="name">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['name'].errors  && !accountForm.controls['name'].pristine">
                                                    El campo nombre es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Usuario*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.user"
                                                    [ngClass]="accountForm.controls['user'].errors  && !accountForm.controls['user'].pristine ? 'is-invalid' : ''"
                                                    formControlName="user">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['user'].errors  && !accountForm.controls['user'].pristine">
                                                    El campo usuario es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Correo
                                                Electronico*</label>
                                            <div class="input-group input-group-merge">
                                                <input type="text" class="form-control" id="basic-default-company"
                                                    [(ngModel)]="formModelData.email"
                                                    [ngClass]="accountForm.controls['email'].errors  && !accountForm.controls['email'].pristine ? 'is-invalid' : ''"
                                                    formControlName="email">
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['email'].errors  && !accountForm.controls['email'].pristine">
                                                    El campo correo electronico es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="row">
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Tipo de
                                                Usuario*</label>
                                            <div class="input-group input-group-merge">
                                                <select class="form-control" [(ngModel)]="formModelData.type_admin"
                                                    [ngClass]="accountForm.controls['type_admin'].errors  && !accountForm.controls['type_admin'].pristine ? 'is-invalid' : ''"
                                                    formControlName="type_admin">
                                                    <option value="">-- Seleccione --</option>
                                                    <option value="1">Administrador</option>
                                                    <option value="2">Director</option>
                                                    <option value="3">Coordinador</option>
                                                    <option value="4">Supervisor</option>
                                                </select>
                                                <div class="invalid-feedback"
                                                    *ngIf="accountForm.controls['type_admin'].errors  && !accountForm.controls['type_admin'].pristine">
                                                    El campo tipo de usuario es obligatorio
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-4 col-lg-4 col-xs-12">
                                            <label class="form-label" for="basic-default-company">Estado*</label>
                                            <div class="input-group input-group-merge">
                                                <label class="switch switch-lg">
                                                    <input type="checkbox" id="updateAccountStatus" class="switch-input" [checked]="formModelData.status == 1 ? true : false" (change)="changedStatus('updateAccountStatus')" />
                                                    <span class="switch-toggle-slider">
                                                        <span class="switch-on">
                                                            <i class="ti ti-check"></i>
                                                        </span>
                                                        <span class="switch-off">
                                                            <i class="ti ti-x"></i>
                                                        </span>
                                                    </span>
                                                    <span class="switch-label">{{!formModelData.status ? 'Inactivo' : 'Activo'}}</span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-4">
                            <div class="card-body text-center">
                                <button type="submit" class="btn btn-primary me-sm-3 me-1">Actualizar Cuenta</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalUpdatePassword" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Actualización Contraseña</h3>
                    <p class="text-muted">Diligencie los siguentes datos</p>
                </div>
                <form [formGroup]="accountForm" id="formAccount" (ngSubmit)="updatePassword()" class="row g-3">
                    <div class="col-lg-12 col-lg-12 col-xs-12">
                        <label class="form-label" for="basic-default-company">Contraseña*</label>
                        <div class="input-group input-group-merge">
                            <input type="text" class="form-control" id="basic-default-company"
                                [(ngModel)]="formModelData.password"
                                [ngClass]="accountForm.controls['password'].errors  && !accountForm.controls['password'].pristine ? 'is-invalid' : ''"
                                formControlName="password">
                            <div class="invalid-feedback"
                                *ngIf="accountForm.controls['name'].errors  && !accountForm.controls['password'].pristine">
                                El campo contraseña es obligatorio
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 text-center">
                        <button type="submit" class="btn btn-primary me-sm-3 me-1">Actualizar contraseña</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalPhoto" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Subir Foto</h3>
                </div>

                <ngx-dropzone (change)="onSelect($event)" [multiple]="false">
                    <ngx-dropzone-label>Seleccione la Fotografia</ngx-dropzone-label>

                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label></ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>

                <div class="text-center mt-4">
                    <button class="btn btn-primary" [disabled]="!files.length" (click)="uploadPhoto()">Subir</button>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">{{text_loading}}</p>
</ngx-spinner>