import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { IStatusPanelParams } from 'ag-grid-community';

@Component({
  selector: 'app-filter-services',
  templateUrl: './filter-services.component.html',
  styleUrls: ['./filter-services.component.css']
})
export class FilterServicesComponent implements IStatusPanelAngularComp {
  
  private params!: IStatusPanelParams;

  agInit(params: IStatusPanelParams): void {
    this.params = params;
    alert()
  }

  onClick(): void {
    alert('Selected Row Count: ' + this.params.api.getSelectedRows().length);
  }
}
