import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { OrderAddComponent } from './order-add/order-add.component';
import { OrderListComponent } from './order-list/order-list.component';
import { AuthGuard } from '../shared/auth.guard';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { OrderChangedUnitComponent } from './order-changed-unit/order-changed-unit.component';

const routes: Routes = [
  {
    path: "service", component: DashboardComponent, canActivate: [AuthGuard], children: [{
      path: "add", component: OrderAddComponent, data: { module: "Servicio", accion: "Crear Servicio" }
    }, {
      path: "list", component: OrderListComponent, data: { module: "Servicio", accion: "Listado de Servicios" }
    }, {
      path: "detail", component: OrderDetailComponent, data: { module: "Servicio", accion: "Detalle del servicio" }
    }, {
      path: "changed-unit", component: OrderChangedUnitComponent, data: { module: "Servicio", accion: "Cambio de unidad" }
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderRoutingModule { }
