<p *ngIf="cellValue.type == 0">{{cellValue.value == 1 ? 'Administrador': cellValue.value == 2 ? 'Director' :
    cellValue.value == 3 ? 'Coordinador' : 'Supervisor' }}</p>
<p *ngIf="cellValue.type == 1">{{cellValue.value == 0 ? 'Inactivo' : 'Activo'}}</p>
<div *ngIf="cellValue.type == 2">

    <a href="javascript:void(0);"  (click)="editAccount(cellValue.value)" style="cursor: pointer;">
        <i class="ti ti-edit"></i>
    </a>
    <a href="javascript:void(0);"  (click)="changedPassword()" style="margin-left: 8px;">
        <i class="ti ti-fingerprint"></i>
    </a>
    <a href="javascript:void(0);"  (click)="deleteAccount(cellValue.value)" style="cursor: pointer; margin-left: 8px;">
        <i class="ti ti-trash"></i>
    </a>
    <a href="javascript:void(0);"  (click)="uploadPhoto(cellValue.value)" style="cursor: pointer;margin-left: 8px" >
        <i class="ti ti-photo-up"></i>
    </a>
</div>