<div class="col-xl">
    <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
            <a class="btn btn-primary" routerLink="/assistant/add"><i class="fas fa-regular fa-circle-plus add-address"></i>&nbsp;&nbsp;
                    Agregar Auxiliar</a>
        </div>
        <div class="card-body">
            <ag-grid-angular style="width: 100%; height: 60vh;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [animateRows]="true"
                [loadingCellRenderer]="loadingCellRenderer" [loadingCellRendererParams]="loadingCellRendererParams"
                (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">Espere un momento...</p>
</ngx-spinner>