<!-- <select name="" id="" class="form-select" [(ngModel)]="statusSelect" (change)="chagedStatus($event)" [disabled]="statusSelect == 6">
    <option value="Seleccione un estado">Seleccione...</option>
    <option [value]="item.id" *ngFor="let item of dataCombo">{{item.name}}</option>
</select> -->



<div class="dropdown">
    <a data-bs-toggle="dropdown" (click)="chagedStatus()" *ngIf="(statusSelect != 6 && statusSelect != 7) && (dataUser.type_admin == 1 || dataUser.type_admin == 2 || dataUser.type_admin == 3)" aria-expanded="false" [style.background-color]="selectColor(statusSelect)" class="status_text">
        {{status_text}}
    </a>

    <a *ngIf="(statusSelect == 6  || statusSelect == 7 || dataUser.type_admin == 4)" [style.background-color]="selectColor(statusSelect)" class="status_text">
        {{status_text}}
    </a>
    <!-- <ul class="dropdown-menu" style="padding: 0;">
        <li *ngFor="let item of dataCombo">
            <a class="dropdown-item" (click)="chagedStatus(item.id)">
                {{item.name}}
            </a>
        </li>
    </ul> -->
</div>
