import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  api_url = environment.url;

  addAccount(data: any) {
    return this.http.post(`${this.api_url}user/register`, data);
  }
  updateAccount(data: any, id: number) {
    return this.http.post(`${this.api_url}user/update/${id}`, data);
  }

  listAccount() {
    return this.http.get(`${this.api_url}user/list`)
  }
  updatePassword(id:any, data:any) {
    return this.http.post(`${this.api_url}user/changePassword/${id}`, data)
  }
  updateStatus(id:any, data:any) {
    return this.http.post(`${this.api_url}user/updateStatus/${id}`, data)
  }
  delete(id:any){
    return this.http.delete(`${this.api_url}user/delete/${id}`)
  }
  uploadFile(data:any, id:any){
    return this.http.post(`${this.api_url}user/uploadPhoto/${id}`, data)
  }
}
