import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitAddComponent } from './unit-add/unit-add.component';
import { UnitEditComponent } from './unit-edit/unit-edit.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { UnitRoutingModule } from './unit-routing.module';
import {AgGridModule} from 'ag-grid-angular'



@NgModule({
  declarations: [
    UnitAddComponent,
    UnitEditComponent,
    UnitListComponent
  ],
  imports: [
    CommonModule,
    UnitRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AgGridModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UnitModule { }
