<div class="col-xl">
    <form [formGroup]="clientForm" (ngSubmit)="addClient()">
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Alta de Clientes</h5>
            </div>
            <div class="card-body">

                <div class="mb-3  col-lg-12 col-xs-12">
                    <label class="form-label" for="basic-default-fullname">Nombre de cliente*</label>
                    <div class="input-group input-group-merge">
                        <input type="text" class="form-control"
                            [ngClass]="clientForm.controls['name'].errors  && !clientForm.controls['name'].pristine ? 'is-invalid' : ''"
                            formControlName="name" [(ngModel)]="formModelData.name">
                        <div class="invalid-feedback"
                            *ngIf="clientForm.controls['name'].errors  && !clientForm.controls['name'].pristine">Ingrese
                            el nombre de cliente</div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label">RFC*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control"
                                    [ngClass]="clientForm.controls['rfc'].errors  && !clientForm.controls['rfc'].pristine ? 'is-invalid' : ''"
                                    formControlName="rfc" [(ngModel)]="formModelData.rfc">
                                <div class="invalid-feedback"
                                    *ngIf="clientForm.controls['rfc'].errors  && !clientForm.controls['rfc'].pristine">
                                    El campo RFC es obligatorio</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label">Código Postal*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" (change)="onChangeSearch($event, 1)"
                                    [ngClass]="clientForm.controls['postal_code'].errors  && !clientForm.controls['postal_code'].pristine ? 'is-invalid' : ''"
                                    formControlName="postal_code" [(ngModel)]="formModelData.postal_code">
                                <div class="invalid-feedback"
                                    *ngIf="clientForm.controls['postal_code'].errors  && !clientForm.controls['postal_code'].pristine">
                                    El codigo postal es obligatorio</div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label">Regímen Fiscal*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control"
                                    [ngClass]="clientForm.controls['local_regime'].errors  && !clientForm.controls['local_regime'].pristine ? 'is-invalid' : ''"
                                    formControlName="local_regime" [(ngModel)]="formModelData.local_regime">
                                <div class="invalid-feedback"
                                    *ngIf="clientForm.controls['local_regime'].errors  && !clientForm.controls['local_regime'].pristine">
                                    El campo regimen fiscal es obligorio</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-12 col-lg-12 col-xs-12">
                            <label class="form-label">Correo Electronico*</label>
                            <div class="input-group input-group-merge">
                                <input type="email" class="form-control"
                                    [ngClass]="clientForm.controls['email'].errors  && !clientForm.controls['email'].pristine ? 'is-invalid' : ''"
                                    formControlName="email" [(ngModel)]="formModelData.email">
                                <div class="invalid-feedback"
                                    *ngIf="clientForm.controls['email'].errors  && !clientForm.controls['email'].pristine">
                                    Ingrese un E-mail valido</div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Direcciones de Origen</h5>
                <button class="btn btn-primary" data-bs-toggle="modal" type="button"
                    data-bs-target="#addAddresOrigin">&nbsp;<i class="fas fa-regular fa-circle-plus add-address"></i>
                    Agregar</button>
            </div>
            <div class="card-body">
                <div class="mb-3  col-lg-12 col-xs-12">
                    <div class="table-responsive text-nowrap">
                        <table class="table">
                            <caption class="ms-4">
                                Listado de direcciones
                            </caption>
                            <thead>
                                <tr>
                                    <th>Calle</th>
                                    <th>Numero</th>
                                    <th>Codigo Postal</th>
                                    <th>Colonia</th>
                                    <th>Alcadia o Municiipo</th>
                                    <th>Estado</th>
                                    <th>Accion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of formModelData.origin_address; let i = index">
                                    <td>
                                        {{item.street}}
                                    </td>
                                    <td>{{item.number}}</td>
                                    <td>
                                        {{item.postal_code}}
                                    </td>
                                    <td>{{item.cologne}}</td>
                                    <td>{{item.municipality}}</td>
                                    <td>
                                        {{item.stated}}
                                    </td>
                                    <td>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="editAddressOrigin(i)"><i class="ti ti-pencil me-1"></i> Editar
                                        </a>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="deleteAddressOrigin(i)"><i
                                                class="ti ti-trash me-1"></i>Eliminar</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Direcciones de Destino</h5>
                <button class="btn btn-primary" data-bs-toggle="modal" type="button"
                    data-bs-target="#addAddresDestination">&nbsp;<i
                        class="fas fa-regular fa-circle-plus add-address"></i>
                    Agregar</button>
            </div>
            <div class="card-body">

                <div class="mb-3  col-lg-12 col-xs-12">
                    <div class="table-responsive text-nowrap">
                        <table class="table">
                            <caption class="ms-4">
                                Listado de Direcciones
                            </caption>
                            <thead>
                                <tr>
                                    <th>Calle</th>
                                    <th>Numero</th>
                                    <th>Codigo Postal</th>
                                    <th>Colonia</th>
                                    <th>Alcadia o Municiipo</th>
                                    <th>Estado</th>
                                    <th>Accion</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of formModelData.destination_address; let i = index">
                                    <td>
                                        {{item.street}}
                                    </td>
                                    <td>{{item.number}}</td>
                                    <td>
                                        {{item.postal_code}}
                                    </td>
                                    <td>{{item.cologne}}</td>
                                    <td>{{item.municipality}}</td>
                                    <td>
                                        {{item.stated}}
                                    </td>
                                    <td>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="editAddressDestination(i)"><i class="ti ti-pencil me-1"></i> Editar
                                        </a>
                                        <a class="dropdown-item" href="javascript:void(0);"
                                            (click)="deleteAddressDestination(i)"><i class="ti ti-trash me-1"></i>Eliminar</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-body text-center">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">Guardar cliente</button>
            </div>
        </div>
    </form>
</div>

<div class="modal fade" id="addAddresOrigin" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Agregar Dirección de Origen</h3>
                    <p class="text-muted">Diligencie los siguentes datos</p>
                </div>
                <form id="formAddOrigin" class="row g-3" (ngSubmit)="addAddressOrigin()">
                    <div class="col-lg-8 col-xs-12 col-md-6">
                        <label class="form-label w-100" for="StretOrigin">Calle</label>
                        <div class="input-group input-group-merge">
                            <input id="StretOrigin" name="StretOrigin" class="form-control credit-card-mask" type="text"
                                aria-describedby="StretOrigin2" required [(ngModel)]="forModelOrigin.street"
                                [ngModelOptions]="{standalone: true}" />
                            <span class="input-group-text cursor-pointer p-1" id="StretOrigin2"><span
                                    class="card-type"></span></span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12 col-sm-12 col-md-6">
                        <label class="form-label" for="numberOrigin">Numero</label>
                        <input type="text" id="numberOrigin" class="form-control" placeholder=""
                            [(ngModel)]="forModelOrigin.number" [ngModelOptions]="{standalone: true}" required />
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="form-label" for="postaOrigin">Codigo Postal</label>
                        <input type="text" id="postaOrigin" class="form-control" placeholder="" (change)="onChangeSearch($event, 2)"
                            [(ngModel)]="forModelOrigin.postal_code" [ngModelOptions]="{standalone: true}" required />
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="form-label" for="coloniaOrigin">Colonia</label>
                        <!-- <select class="form-select" [(ngModel)]="forModelOrigin.cologne" [ngModelOptions]="{standalone: true}">
                            <option value="">Seleccione una opcion</option>
                            <option *ngFor="let item of cologneAddressOrigin" value="{{item}}">{{item}}</option>
                        </select> -->
                        <input type="text" id="coloniaOrigin" class="form-control expiry-date-mask" placeholder=""
                            [(ngModel)]="forModelOrigin.cologne" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <label class="form-label" for="alcadiaOrigin">Alcaldia o Municipio</label>
                        <div class="input-group input-group-merge">
                            <input type="text" id="alcadiaOrigin" class="form-control" placeholder="" required
                                [(ngModel)]="forModelOrigin.municipality" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label class="form-label" for="statedOrigin">Estado</label>
                        <div class="input-group input-group-merge">
                            <input type="text" id="statedOrigin" class="form-control" placeholder="" required
                                [(ngModel)]="forModelOrigin.stated" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-primary me-sm-3 me-1">Guardar</button>
                        <button type="reset" class="btn btn-label-secondary btn-reset" data-bs-dismiss="modal"
                            aria-label="Close">
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addAddresDestination" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Agregar Dirección de Destino</h3>
                    <p class="text-muted">Diligencie los siguentes datos</p>
                </div>
                <form id="formDestination" class="row g-3" (ngSubmit)="addAddressDestination()">
                    <div class="col-lg-8 col-xs-12 col-md-6">
                        <label class="form-label w-100" for="StretDestination">Calle</label>
                        <div class="input-group input-group-merge">
                            <input id="StretDestination" name="StretDestination" class="form-control credit-card-mask"
                                type="text" aria-describedby="StretDestination2"
                                [(ngModel)]="forModelDestination.street" [ngModelOptions]="{standalone: true}" />
                            <span class="input-group-text cursor-pointer p-1" id="StretDestination2"><span
                                    class="card-type"></span></span>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12 col-sm-12 col-md-6">
                        <label class="form-label" for="numberDestination">Numero</label>
                        <input type="text" id="numberDestination" class="form-control" placeholder=""
                            [(ngModel)]="forModelDestination.number" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="form-label" for="postaDestination">Codigo Postal</label>
                        <input type="text" id="postaDestination" class="form-control" placeholder="" (change)="onChangeSearch($event, 3)"
                            [(ngModel)]="forModelDestination.postal_code" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="form-label" for="coloniaDestination">Colonia</label>
                        <!-- <select class="form-select" [(ngModel)]="forModelDestination.cologne" [ngModelOptions]="{standalone: true}">
                            <option value="">Seleccione una opcion</option>
                            <option *ngFor="let item of cologneAddressDestination" value="{{item}}">{{item}}</option>
                        </select> -->
                        <input type="text" id="coloniaDestination" class="form-control expiry-date-mask" placeholder=""
                            [(ngModel)]="forModelDestination.cologne" [ngModelOptions]="{standalone: true}" />
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <label class="form-label" for="alcadiaDestination">Alcaldia o Municipio</label>
                        <div class="input-group input-group-merge">
                            <input type="text" id="alcadiaDestination" class="form-control cvv-code-mask" placeholder=""
                                [(ngModel)]="forModelDestination.municipality" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label class="form-label" for="statedDestination">Estado</label>
                        <div class="input-group input-group-merge">
                            <input type="text" id="statedDestination" class="form-control cvv-code-mask" placeholder=""
                                [(ngModel)]="forModelDestination.stated" [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <button type="submit" class="btn btn-primary me-sm-3 me-1">Guardar</button>
                        <button type="reset" class="btn btn-label-secondary btn-reset" data-bs-dismiss="modal"
                            aria-label="Close">
                            Cancelar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">{{text_loading}}</p>
</ngx-spinner>