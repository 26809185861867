import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ITooltipParams } from "ag-grid-community";
import { ITooltipAngularComp } from "ag-grid-angular";

@Component({
  selector: 'app-address-tooltip',
  templateUrl: './address-tooltip.component.html',
  styleUrls: ['./address-tooltip.component.css']
})
export class AddressTooltipComponent {

  constructor() { }

  ngOnInit(): void {
  }

  public cellValue!: any;
  public data!: any;
  public color!: string;

  // gets called once before the renderer is used
  agInit(params: { color: string } & ITooltipParams): void {
    this.cellValue = params;
    this.color = params.color || 'white';

  }
}
