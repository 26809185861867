<div class="row">
    <div class="col-sm-6 col-lg-3 mb-4">
        <div class="card card-border-shadow-primary">
            <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                        <span class="avatar-initial rounded bg-label-primary"><i class="ti ti-clock ti-md"></i></span>
                    </div>
                    <h4 class="ms-1 mb-0">{{conut[0].pending == null ? 0 : conut[0].pending}}</h4>
                </div>
                <p class="mb-1">Servicios Pendientes</p>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-4">
        <div class="card card-border-shadow-warning">
            <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                        <span class="avatar-initial rounded bg-label-warning"><i class="ti ti-truck ti-md"></i></span>
                    </div>
                    <h4 class="ms-1 mb-0">{{conut[0].in_route == null ? 0 : conut[0].in_route}}</h4>
                </div>
                <p class="mb-1">Servicios en Proceso</p>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-3 mb-4">
        <div class="card card-border-shadow-danger">
            <div class="card-body">
                <div class="d-flex align-items-center mb-2 pb-1">
                    <div class="avatar me-2">
                        <span class="avatar-initial rounded bg-label-danger"><i class="ti ti-check ti-md"></i></span>
                    </div>
                    <h4 class="ms-1 mb-0">{{conut[0].good == null ? 0 : conut[0].good}}</h4>
                </div>
                <p class="mb-1">Servicios Exitosos</p>
            </div>
        </div>
    </div>
</div>

<div class="col-xl">
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-lg-10" [@fade]="isVisible ? 'visible' : 'hidden'">
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="form-label" for="basic-default-company">Cliente</label>
                            <div class="input-group input-group-merge">
                                <select name="" id="" class="form-select" [(ngModel)]="formModel.client"
                                    (change)="onSelectClient()">
                                    <option value="">-- Todos --</option>
                                    <option *ngFor="let item of dataClient" [value]="item.id">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <label class="form-label" for="basic-default-company">Estado</label>
                            <div class="input-group input-group-merge">
                                <select name="" id="" class="form-select" [(ngModel)]="formModel.status"
                                    (change)="onSelectStatus()">
                                    <option value="">-- Todos --</option>
                                    <option *ngFor="let item of data_combo" [value]="item.id">{{item.name}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="col-lg-2">
                            <label class="form-label" for="basic-default-company" >Fecha Creación</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="date_date">
                            </div>
                        </div> -->
                        <div class="col-lg-2">
                            <label class="form-label" for="basic-default-company">Fecha Cargue</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="upload_date"
                                    [(ngModel)]="formModel.upload_date">
                                <span class="input-group-text" (click)="removeDate('upload_date')">
                                    <i [ngClass]="formModel.upload_date ? 'ti ti-x' : 'ti ti-calendar'"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <label class="form-label" for="basic-default-company">Fecha Descargue</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="download_date"
                                    [(ngModel)]="formModel.download_date">
                                <span class="input-group-text" (click)="removeDate('download_date')">
                                    <i [ngClass]="formModel.download_date ? 'ti ti-x' : 'ti ti-calendar'"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-2 float-right">
                    <br>

                    <a class="btn btn-info btn-service" (click)="onGridReady({})">
                        <i class="ti ti-refresh"></i>
                    </a>
                    <a class="btn btn-info btn-service" routerLink="/service/add"
                        *ngIf="dataUser.type_admin == 1 || dataUser.type_admin == 2 || dataUser.type_admin == 3">
                        <i class="ti ti-plus"></i>
                    </a>
                </div>
            </div>


        </div>
        <div class="card-body">

            <!-- <div id="jstree-basic" style="display: block;">
                <ul>
                    <li *ngFor="let item of rowData.data_group" data-jstree='{"icon" : "ti ti-truck"}'>
                        Unidad: {{item.unit}}
                        <ul>
                            <li *ngFor="let item1 of item.data" data-jstree='{"icon" : "ti ti-truck}'>
                                Fecha: {{item1.date}}
                                <ul>
                                    <li class="grid-data">
                                        <ag-grid-angular style="width: 100%; height: 20vh;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                                            [defaultColDef]="defaultColDef" [rowData]="item1.data" [animateRows]="true"
                                            [loadingCellRenderer]="loadingCellRenderer" [loadingCellRendererParams]="loadingCellRendererParams"
                                            [tooltipShowDelay]="tooltipShowDelay" [tooltipHideDelay]="tooltipHideDelay" [statusBar]="statusBar"
                                            (gridReady)="onGridReady($event)"></ag-grid-angular>
                                    </li>
                                </ul>
                            </li>
                            
                        </ul>
                    </li>
                </ul>
            </div> -->
            <ag-grid-angular style="width: 100%; height: 60vh;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [animateRows]="true"
                [loadingCellRenderer]="loadingCellRenderer" [loadingCellRendererParams]="loadingCellRendererParams"
                [tooltipShowDelay]="tooltipShowDelay" [tooltipHideDelay]="tooltipHideDelay" (cellClicked)="onCellClicked($event)"
                [statusBar]="statusBar" (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </div>
    </div>
</div>


<div class="modal fade" id="modalEvidence" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Adjuntar evidencias</h3>
                </div>

                <ngx-dropzone (change)="onSelect($event)">
                    <ngx-dropzone-label>Seleccione las evidencias</ngx-dropzone-label>

                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
                        [removable]="true" (removed)="onRemove(f)">
                        <ngx-dropzone-label></ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                </ngx-dropzone>

                <div class="text-center mt-4">
                    <button class="btn btn-primary" [disabled]="!files.length" (click)="uploadEvidences()">Cargar
                        evidencias</button>
                    <!-- <p class="text-muted">Diligencie los siguentes datos</p> -->
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalEvidenceShow" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-dialog-centered1 modal-simple modal-add-new-cc">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Evidencias de {{text_type}}</h3>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-12 mb-4 mb-lg-0" *ngFor="let item of evidences">
                        <img src="{{url_page}}{{item.img}}" class="w-100 shadow-1-strong rounded mb-4"
                            alt="Evidencias de carga tmh logistica" />
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalLogs" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-simple modal-refer-and-earn">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Histórico de actualizaciones</h3>
                </div>

                <div class="row" *ngFor="let item of logs; let i = index">
                    <div class="col-lg-6 mb-3">
                        <p class="mb-0 text-primary font-weight-bold">Ultima actualización:</p>
                        <ul class="mb-0">
                            <li>
                                <b class="text-dark">Fecha y hora: </b><span class="text-dark">{{item.created_at |
                                    date:'dd/MM/yyyy, HH:mm'}}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 mb-3">
                        <p class="mb-0 text-primary font-weight-bold">Realizado por:</p>
                        <ul class="mb-0">
                            <li><span class="text-dark">{{item.name}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalObservation" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-simple modal-refer-and-earn">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Observación</h3>
                </div>

                <label class="form-label" for="basic-default-company">Observacion</label>
                <div class="input-group input-group-merge">
                    <textarea name="observation" class="form-control" cols="30" rows="10"
                        [(ngModel)]="dataSelect.observation"></textarea>
                </div>

                <div class="text-center mt-4">
                    <button class="btn btn-primary" (click)="updateObservationSend()">Guardar</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalChangedUnit" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-simple modal-refer-and-earn">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Observación</h3>
                </div>

                <label class="form-label" for="basic-default-company">Observacion</label>
                <div class="input-group input-group-merge">
                    <textarea name="observation" class="form-control" cols="30" rows="10" [(ngModel)]="dataSelect.observation"></textarea>
                </div>

                <div class="text-center mt-4">
                    <button class="btn btn-primary" (click)="updateObservationSend()">Guardar</button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalStatus" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-sm modal-simple modal-refer-and-earn">
        <div class="modal-content p-1 p-md-1">
            <div class="modal-header">
                <h4 class="modal-title text-center w-100">Actualizar estado</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div class="text-center mb-4">
                    <h6 class="mb-2">Selecciona el estado que deseas actualizar</h6>
                </div>

                <div class="row">
                    <div class="col-lg-6 d-grid gap-1 mb-1" *ngFor="let item of dataCombo">
                        <input type="radio" class="btn-check" name="status_option" id="btn-check-{{item.id}}" [value]="item.id"
                            autocomplete="off">
                        <label class="btn text-white btn-sm" [ngClass]="selectColor(item.id)"
                            for="btn-check-{{item.id}}">{{item.name}}</label>
                    </div>
                </div>
                <!-- <div class="d-grid gap-1 col-lg-12 mx-auto">
                    <button *ngFor="let item of dataCombo" (click)="changedStatus(item.id)" type="button" class="btn mb-1 btn-outline-info btn-lg waves-effect waves-light rounded-pill">{{item.name}}</button>
                </div> -->
            </div>
            <div class="modal-footer d-block m-0 p-0">
                <div class="row m-0 p-0">
                    <div class="col-lg-6 m-0">
                        <button type="button" class="btn btn-outline-primary m-1 w-100" data-bs-dismiss="modal"
                            aria-label="Close">Cancelar</button>
                    </div>
                    <div class="col-lg-6 m-0">
                        <button type="button" class="btn btn-primary m-1 w-100" (click)="changedStatus()">Actualizar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalEvidenceOption" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-sm modal-simple modal-refer-and-earn">
        <div class="modal-content p-1 p-md-1">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" style="top: 0; right: 0;"
                    aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h4 class="mb-2">Seleccione una opción</h4>
                </div>

                <div class="d-grid gap-1 col-lg-12 mx-auto">
                    <button *ngIf="(status_id == 3 && !evidence_1  ) && dataUser.type_admin != 4 "
                        (click)="modalActiveEvidence(service_id, status_id)" type="button"
                        class="btn mb-1 btn-outline-info btn-lg waves-effect waves-light rounded-pill">Subir
                        evidencias</button>
                    <button *ngIf="evidence_1" (click)="modalOpenEvidence(service_id, 3)" type="button"
                        class="btn mb-1 btn-outline-info btn-lg waves-effect waves-light rounded-pill">Evidencias de
                        Carga</button>
                    <button *ngIf="(status_id == 4 && !evidence_2 ) && dataUser.type_admin != 4"
                        (click)="modalActiveEvidence(service_id, status_id)" type="button"
                        class="btn mb-1 btn-outline-info btn-lg waves-effect waves-light rounded-pill">Subir Evidencias
                        de Descarga</button>
                    <button *ngIf="evidence_2" (click)="modalOpenEvidence(service_id, 4)" type="button"
                        class="btn mb-1 btn-outline-info btn-lg waves-effect waves-light rounded-pill">Evidencias de
                        Descarga</button>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">{{text_loading}}</p>
</ngx-spinner>