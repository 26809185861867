import { Component } from '@angular/core';
import { GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionsComponent } from 'src/app/cell-renderer/actions/actions.component';
import { LoadingGridComponent } from 'src/app/cell-renderer/loading-grid/loading-grid.component';
import { AssistantService } from 'src/app/services/assistant.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-assistant-list',
    templateUrl: './assistant-list.component.html',
    styleUrls: ['./assistant-list.component.css']
})
export class AssistantListComponent {
    constructor(
        private apiAssistant: AssistantService,
        private utils: UtilsService, 
        private spinner: NgxSpinnerService
    ) { }
    address: any = []

    columnDefs = [
        { headerName: "Accion", width: 95, cellRenderer: ActionsComponent, cellRendererParams: { parent: this, url_edit: "/assistant/update/" } },
        {
            headerName: "Datos Personales", children: [
                { field: 'name', headerName: "Nombre" },
                { field: 'last_name', headerName: "Apellidos" },
                { field: 'cell_phone', headerName: "Celular" },
                { field: 'birthday_date', headerName: "Fecha Nacimiento" },
                { field: 'municipality', headerName: "Alcaldia o municipio" },

            ]
        }, {
            headerName: "Datos Bancarios", children: [
                { field: 'bank_data.holder_name', headerName: "Nombre del Titular" },
                { field: 'bank_data.bank_name', headerName: "Banco" },
                { field: 'bank_data.account_number', headerName: "Numero de Cuenta o Tarjeta" },
                { field: 'bank_data.account_type', headerName: "Tipo de Cuenta" }
            ]
        }
    ];

    public loadingCellRenderer: any = LoadingGridComponent;
    public loadingCellRendererParams: any = {
        loadingMessage: 'Espere un momento...',
    };

    public defaultColDef = {
        sortable: true,
        filter: true,
    };

    private gridApi!: GridApi;

    public rowData: any = [];

    ngOnInit(): void {
    }

    event_text = ''

    onGridReady(params: any) {
        this.spinner.show()
        this.apiAssistant.listAssistant().subscribe((resp) => {
            this.rowData = resp;
            this.spinner.hide()
        })
    }
    actionDelete(id: number) {
        this.utils.alertSwalConfir({title:"Eliminación", text:"Esta seguro que desea eliminar el auxiliar seleccionado", icon: "warning", btn_confirm: "Elimniar"})
        .then((result) => {
            if(result.isConfirmed){
                this.spinner.show()
                this.apiAssistant.deleteAssistant(id).subscribe((resp) => {
                    this.spinner.hide()
                    this.utils.alertSwal({title: "Operación Exitosa", text: "Se ha realizado la eliminacion del auxiliar de forma exitosa", icon: "success"})
                    this.onGridReady({})
                }, (error) => {
                    this.spinner.hide()
                    this.utils.alertSwal({title: "Error", text: "Se ha presentado un error al intentar eliminar el auxiliar", icon: "error"})
                })
                
            }
        })
    }
}
