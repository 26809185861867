import { Component } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DriverService } from 'src/app/services/driver.service';
import { UtilsService } from 'src/app/services/utils.service';

declare const $:any;

@Component({
    selector: 'app-driver-add',
    templateUrl: './driver-add.component.html',
    styleUrls: ['./driver-add.component.css']
})
export class DriverAddComponent {
    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private spinner: NgxSpinnerService,
        private apiDriver: DriverService
    ) { }

    formModelData: any = {
        name: null,
        last_name: null,
        cell_phone: null,
        birthday_date: null,
        municipality: null,
        bank_data: {
            holder_name: null,
            bank_name: null,
            account_number: null,
            account_type: null
        }
    }

    driverForm!: FormGroup



    ngOnInit(): void {

        this.driverForm = this.fb.group({
            name: ['', Validators.required],
            last_name: ['', Validators.required],
            cell_phone: ['', Validators.required],
            birthday_date: ['', Validators.required],
            municipality: ['', Validators.required],
            bank_holder_name: ['', Validators.required],
            bank_name: ['', Validators.required],
            bank_account_number: ['', Validators.required],
            bank_account_type: ['', Validators.required]
        })
    }

    addDriver() {
        if (this.driverForm.valid) {
            this.spinner.show()
            this.apiDriver.addDriver(this.formModelData).subscribe((resp: any) => {
                this.spinner.hide()
                $("#formDriver")[0].reset()
                this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })

            }, (error) => {
                this.spinner.hide()
                this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al insertar conductor", icon: "error" })
            })
        } else {
            this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
        }
    }

    get registerFormControl() {
        return this.driverForm.controls;
    }
}
