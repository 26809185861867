import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor() { }

    alertSwal(data: any) {
        Swal.fire({
            title: data.title,
            text: data.text,
            icon: data.icon,
        });
    }
    alertSwalConfir(data: any){
        return Swal.fire({
            title: data.title,
            text: data.text,
            icon: data.icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: data.btn_confirm
        })
    }
    getDataUser(){
        let dataU:any = localStorage.getItem("dataUser");
        return JSON.parse(dataU)
    }
}
