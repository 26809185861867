import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DriverService {
    api_url = environment.url;

    constructor(private http: HttpClient) { }

    addDriver(data: any) {
        return this.http.post(`${this.api_url}driver/add`, data);
    }

    updateDriver(data: any, id: number) {
        return this.http.post(`${this.api_url}driver/update/${id}`, data);
    }

    listDriver() {
        return this.http.get(`${this.api_url}driver/list`)
    }
    getId(id: number) {
        return this.http.get(`${this.api_url}driver/show/${id}`)
    }
    getInfo() {
        return this.http.get(`${this.api_url}driver/getCombo`)
    }
    deleteDriver(id: any) {
        return this.http.get(`${this.api_url}driver/delete/${id}`)
    }
}
