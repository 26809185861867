import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { UnitListComponent } from './unit-list/unit-list.component';
import { UnitAddComponent } from './unit-add/unit-add.component';
import { UnitEditComponent } from './unit-edit/unit-edit.component';
import { AuthGuard } from '../shared/auth.guard';

const routes: Routes = [{
    path: "unit", component: DashboardComponent, canActivate: [AuthGuard], children: [{
        path: "list", component: UnitListComponent, data: { module: "Unidad", accion: "Listado de Unidades" }
    }, {
        path: "add", component: UnitAddComponent, data: { module: "Unidad", accion: "Creación de Unidad" }
    }, {
        path: "update/:id", component: UnitEditComponent, data: { module: "Unidad", accion: "Actualización de Unidad" }
    }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UnitRoutingModule { }