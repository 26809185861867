import { Component } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ClientService } from 'src/app/services/client.service';
import { ActivatedRoute } from '@angular/router';

declare const $: any;
declare const google: any;

@Component({
    selector: 'app-client-edit',
    templateUrl: './client-edit.component.html',
    styleUrls: ['./client-edit.component.css']
})
export class ClientEditComponent {
    clientForm!: FormGroup
    submitted = false
    text_loading = "Espere un momento..."

    private idClient: number = 0;

    constructor(
        private fb: FormBuilder,
        private apiClient: ClientService,
        private utils: UtilsService,
        private spinner: NgxSpinnerService,
        private route: ActivatedRoute
    ) {
        this.spinner.show()
        this.route.params.subscribe(params => {
            this.idClient = params["id"]
            this.getDataClient(params["id"])
        })
    }

    editOrigin = false
    editDestination = false
    indexOrigin: any = null
    indexDestination: any = null

    cologneAddressDestination: any = [];
    cologneAddressOrigin: any = [];

    forModelDestination = {
        street: null,
        number: null,
        postal_code: null,
        cologne: null,
        municipality: null,
        stated: null
    }

    forModelOrigin = {
        street: null,
        number: null,
        postal_code: null,
        cologne: null,
        municipality: null,
        stated: null
    }

    formModelData: any = {
        name: null,
        rfc: null,
        postal_code: null,
        local_regime: null,
        email: null,
        destination_address: [],
        origin_address: []
    }

    colognes: any = []

    geocoder: any;

    getDataClient(id: any) {
        this.apiClient.getId(id).subscribe((resp) => {
            this.formModelData = resp
            this.spinner.hide()
        })
    }

    ngOnInit(): void {

        this.clientForm = this.fb.group({
            name: ['', Validators.required],
            rfc: ['', Validators.required],
            postal_code: ['', Validators.required],
            local_regime: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        })
    }

    onChangeSearch(val: any, type: any) {
        val = val.target.value
        this.geocoder = new google.maps.Geocoder();
        if (val.length >= 3) {
            this.spinner.show()
            this.text_loading = "Validando codigo postal"
                this.geocoder.geocode( { 'address': val}, (result:any, status:any) => {
                    this.spinner.hide()
                    if(status == 'OK'){

                        if (type == 2) {
                            this.forModelOrigin.cologne  = result[0].hasOwnProperty('postcode_localities') ? result[0].postcode_localities[0] : ''
                            this.forModelOrigin.municipality = result[0].address_components[1].long_name
                            this.forModelOrigin.stated = result[0].address_components[2].long_name
                        } else if (type == 3) {
                            this.forModelDestination.cologne  = result[0].hasOwnProperty('postcode_localities') ? result[0].postcode_localities[0] : ''
                            this.forModelDestination.municipality = result[0].address_components[1].long_name
                            this.forModelDestination.stated = result[0].address_components[2].long_name
                        }

                    }else{
                        alert("Ingrese un codigo postal valido")
                    }

                    
                });
            // this.apiClient.getPostal(val).subscribe((resp: any) => {
            //     if (resp.length > 0) {
            //         this.colognes = resp[0]
            //         if (type == 2) {
            //             this.cologneAddressOrigin = this.colognes.data
            //             this.forModelOrigin.stated = this.cologneAddressOrigin[0].stated
            //         } else if (type == 3) {
            //             this.cologneAddressDestination = this.colognes.data
            //             this.forModelDestination.stated = this.cologneAddressDestination[0].stated
            //         }
            //     } else {
            //         alert("Ingrese un codigo postal valido")
            //     }
            //     this.spinner.hide()
            // })
        }
    }

    get registerFormControl() {
        return this.clientForm.controls;
    }

    addAddressDestination() {
        if (this.editDestination) {
            this.formModelData.destination_address[this.indexDestination] = this.forModelDestination
        } else {
            this.formModelData.destination_address.push(this.forModelDestination)
        }

        $("#formDestination")[0].reset()
        this.resetForm();
        $("#addAddresDestination").modal("hide")
        this.editDestination = false
        this.cologneAddressDestination = []
    }
    editAddressDestination(index: number) {
        this.forModelDestination = this.formModelData.destination_address[index]
        this.editDestination = true
        this.indexDestination = index
        this.onChangeSearch({ target: { value: this.forModelDestination.postal_code } }, 3)
        $("#addAddresDestination").modal("show")
    }
    deleteAddressDestination(i: number) {
        this.formModelData.destination_address.splice(i, 1)
    }
    resetForm() {
        this.forModelDestination = {
            street: null,
            number: null,
            postal_code: null,
            cologne: null,
            municipality: null,
            stated: null
        }

        this.forModelOrigin = {
            street: null,
            number: null,
            postal_code: null,
            cologne: null,
            municipality: null,
            stated: null
        }
    }
    resetAllForm() {
        this.formModelData = {
            name: null,
            rfc: null,
            postal_code: null,
            local_regime: null,
            email: null,
            destination_address: [],
            origin_address: []
        }
    }

    addAddressOrigin() {
        if (this.editOrigin) {
            this.formModelData.origin_address[this.indexOrigin] = this.forModelOrigin
        } else {
            this.formModelData.origin_address.push(this.forModelOrigin)
        }

        $("#formAddOrigin")[0].reset()
        this.resetForm();
        $("#addAddresOrigin").modal("hide")
        this.editOrigin = false
        this.cologneAddressOrigin = []
    }
    editAddressOrigin(index: number) {
        this.forModelOrigin = this.formModelData.origin_address[index]
        this.indexOrigin = index
        this.editOrigin = true
        this.onChangeSearch({ target: { value: this.forModelOrigin.postal_code } }, 2)
        $("#addAddresOrigin").modal("show")
    }
    deleteAddressOrigin(i: number) {
        this.formModelData.origin_address.splice(i, 1)
        this.resetForm()
    }
    addClient() {
        if (!this.formModelData.origin_address.length) {
            this.utils.alertSwal({ title: "Advertencia", text: "Ingrese al menos una direccion de origen", icon: "warning" })
        }
        else if (!this.formModelData.destination_address.length) {
            this.utils.alertSwal({ title: "Advertencia", text: "Ingrese al menos una direccion de destino", icon: "warning" })
        }
        if (this.clientForm.valid) {
            this.text_loading = "Actualizando Información..."
            this.spinner.show()
            this.apiClient.updateClient(this.formModelData, this.idClient).subscribe((resp: any) => {
                this.spinner.hide()
                this.utils.alertSwal({ text: resp.msg, icon: "success", title: "Actualización Exitosa." })
                this.getDataClient(this.idClient)
            }, (error) => {
                this.utils.alertSwal({ text: "Se ha presentado un error al actualizar el cliente.", icon: "success", title: "Error." })
                this.spinner.hide()
            })
        }
    }
}
