import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
declare const $:any;

@Component({
  selector: 'app-btn-action',
  templateUrl: './btn-action.component.html',
  styleUrls: ['./btn-action.component.css']
})
export class BtnActionComponent {
  constructor() { }

  ngOnInit(): void {

    $('[data-toggle="tooltip"]').tooltip()
  }

  public cellValue!: any;
  dataUser: any = {}

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = params;

    this.dataUser = this.cellValue.parent.dataUser

  }

  // gets called whenever the user gets the cell to refresh
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.cellValue = "";
    return true;
  }

  deleteEvent() {
    this.cellValue.parent.cancelOrder(this.cellValue.data.id)
  }

  dowloandService(){
    this.cellValue.parent.dowloandService(this.cellValue.data.id)
  }

  logEvent(){
    this.cellValue.parent.logsService(this.cellValue.data.logs)
  }
  updateObservation(){
    this.cellValue.parent.updateObservation(this.cellValue.data)
  }
  openModalUnit(id:any){
    this.cellValue.parent.openModalChangedUnit(id, this.cellValue.data.unit_id)
  }
}
