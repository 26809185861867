import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UnitService {

    api_url = environment.url;

    constructor(private http: HttpClient) { }

    addUnit(data: any) {
        return this.http.post(`${this.api_url}unit/add`, data);
    }

    updateUnit(data: any, id: number) {
        return this.http.post(`${this.api_url}unit/update/${id}`, data);
    }

    listUnit() {
        return this.http.get(`${this.api_url}unit/list`)
    }
    getId(id: number) {
        return this.http.get(`${this.api_url}unit/show/${id}`)
    }
    getInfo() {
        return this.http.get(`${this.api_url}unit/getCombo`)
    }
    deleteUnit(id: any) {
        return this.http.get(`${this.api_url}unit/delete/${id}`)
    }
}
