import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    api_url = environment.url;

    constructor(private http: HttpClient) { }

    login(data:any) {
        return this.http.post(`${this.api_url}login`, data);
    }

}
