import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssistantService } from 'src/app/services/assistant.service';
import { ClientService } from 'src/app/services/client.service';
import { DriverService } from 'src/app/services/driver.service';
import { UnitService } from 'src/app/services/unit.service';
import { UtilsService } from 'src/app/services/utils.service';

import { forkJoin } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';

declare const $: any;
declare const google: any;

@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.css']
})
export class OrderAddComponent {
  constructor(
    private fb: FormBuilder,
    private utils: UtilsService,
    private spinner: NgxSpinnerService,
    private apiClient: ClientService,
    private apiUnit: UnitService,
    private apiAssitant: AssistantService,
    private apiDriver: DriverService,
    private apiService: OrderService
  ) { }

  serviceForm!: FormGroup
  formModelData: any = {
    client: "",
    origin: "",
    destination: "",
    unit: "",
    driver: "",
    assistant: "",
    bands: "",
    roller_skates: "",
    beach: "",
    devils: "",
    mats: "",
    cartons: "",
    charging_hour: "",
    upload_date: "",
    download_date: "",
    download_time: "",
    assistants: [],
    address: [],
    unified: null
  }
  clientSelected: any = {
    destination_address: [],
    origin_address: []
  }

  text_loading = "Cargando Información"

  isUnified = false

  safety_equipment: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  dataClient: any = []
  dataDriver: any = []
  dataAssistant: any = []
  dataUnit: any = []
  submitted: boolean = false

  dataValidUnified:any = {}

  changed_label_origin = false
  changed_label_destination = false

  dataUser: any = {}

  options_autocomple = {
    fields: ["address_components", "formatted_address", "geometry", "icon", "name"],
    strictBounds: false,
    types: ['address']
  };

  autocomple_origin() {
    this.changed_label_origin = !this.changed_label_origin
    if (this.changed_label_origin) {
      setTimeout(() => {
        const input_origin = document.getElementById("origin_address") as HTMLInputElement;
        const autocomplete_ori = new google.maps.places.Autocomplete(input_origin, this.options_autocomple);
        google.maps.event.addListener(autocomplete_ori, 'place_changed', () => {
          var place = autocomplete_ori.getPlace();
          this.formModelData.origin = place.formatted_address
        })
      }, 500);
    }
  }

  autocomple_destination() {
    this.changed_label_destination = !this.changed_label_destination
    if (this.changed_label_destination) {
      setTimeout(() => {
        const input_dest = document.getElementById("destination_address") as HTMLInputElement;
        const autocomplete_dest = new google.maps.places.Autocomplete(input_dest, this.options_autocomple);
        google.maps.event.addListener(autocomplete_dest, 'place_changed', () => {
          var place = autocomplete_dest.getPlace();
          this.formModelData.destination = place.formatted_address
        })
      }, 500);

    }
  }

  ngOnInit(): void {

    this.spinner.show()

    this.dataUser = this.utils.getDataUser()

    const servicio1$ = this.apiAssitant.getInfo();
    const servicio2$ = this.apiClient.getInfo();
    const servicio3$ = this.apiDriver.getInfo();
    const servicio4$ = this.apiUnit.getInfo();

    forkJoin([servicio1$, servicio2$, servicio3$, servicio4$]).subscribe(([resp1, resp2, resp3, resp4]) => {
      this.dataAssistant = resp1
      this.dataClient = resp2
      this.dataDriver = resp3
      this.dataUnit = resp4

      this.spinner.hide()
    })

    this.serviceForm = this.fb.group({
      client: ['', Validators.required],
      origin: ['', Validators.required],
      destination: ['', Validators.required],
      unit: ['', Validators.required],
      driver: ['', Validators.required],
      assistant: ['', Validators.required],
      charging_hour: ['', Validators.required],
      upload_date: ['', Validators.required],
      download_date: ['', Validators.required],
      download_time: ['', Validators.required],
      bands: ['', Validators.required],
      roller_skates: ['', Validators.required],
      beach: ['', Validators.required],
      devils: ['', Validators.required],
      mats: ['', Validators.required],
      cartons: ['', Validators.required]
    })

  }


  get registerFormControl() {
    return this.serviceForm.controls;
  }
  resetFormModel() {
    this.formModelData = {
      client: "",
      origin: "",
      destination: "",
      unit: "",
      driver: "",
      assistant: "",
      bands: "",
      roller_skates: "",
      beach: "",
      devils: "",
      mats: "",
      cartons: "",
      charging_hour: "",
      upload_date: "",
      download_date: "",
      download_time: "",
      assistants: [],
      address: [],
      unified: null
    }
  }

  addService(form: any) {
    this.submitted = true

    let validation: any = this.formModelData.assistants.length > 0 ? this.formModelData.assistants.filter((resp: any) => {
      if (resp.assistant_id == '') {
        return resp
      }
    }) : []

    let validationAddress: any = this.formModelData.address.length > 0 ? this.formModelData.address.filter((resp: any) => {
      if (resp.origin == '' || resp.destination == '') {
        return resp
      }
    }) : []

    if (validation.length && validationAddress.length) {
      this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
      return
    }
    if (this.serviceForm.valid) {
      this.spinner.show()
      this.text_loading = "Almacenando Información..."
      this.formModelData.user_id = this.dataUser.id
      this.apiService.addService(this.formModelData).subscribe((resp: any) => {
        this.spinner.hide()
        form.form.markAsPristine();
        form.resetForm();
        this.formModelData.assistant = ""
        this.formModelData.driver = ""
        this.formModelData.unified = ""
        this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
        this.changed_label_destination = false
        this.changed_label_origin = false
        this.isUnified = false
      }, (error) => {
        this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al crear el servicio", icon: "error" })
      })
    } else {
      this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
    }

  }
  changedValidUnified(event: any){
    this.spinner.show()
    this.apiService.validUnified({unit: event.target.value, destination: this.formModelData.destination}).subscribe((resp:any) => {
      if(resp.data != null && Object.keys(resp.data).length ){
        $("#modalValidUnified").modal("show")
        this.dataValidUnified = resp.data
        this.formModelData.assistant = resp.data.assistant_id
        this.formModelData.driver = resp.data.driver_id
        this.formModelData.unified = resp.data.id
      }else{
        this.isUnified = false
        this.dataValidUnified = {}
        this.formModelData.assistant_id = ""
        this.formModelData.driver = ""
        this.formModelData.unified = ""
      }
      this.spinner.hide()
    }, (error) => {
      this.spinner.hide()
      alert("Se ha presentado un error al validar la informacion")
    })
  }

  clickModalValidUnified(event:any){
    if(event == true){
      this.isUnified = true
    }else{
      this.isUnified = false  
      this.formModelData.assistant = ""
      this.formModelData.driver = ""
      this.formModelData.unified = ""
    }
    $("#modalValidUnified").modal("hide")
  }

  onSelectChange(event: any) {

    this.clientSelected.origin_address = []
    this.clientSelected.destination_address = []
    const selectedValue = event.target.value

    let filt = this.dataClient.filter((resp: any) => {
      if (resp.id == selectedValue)
        return resp
    })
    this.clientSelected = filt[0]
  }
  addAuxAssistant() {
    this.formModelData.assistants.push({ assistant_id: '' })
  }

  addAddess() {
    this.formModelData.address.push({ origin: '', destination: '', input_origin: false, input_destination: false })
  }

  deleteAssitant(index: any) {
    this.formModelData.assistants.splice(index, 1)
  }

  deleteAddress(index: any) {
    this.formModelData.address.splice(index, 1)
  }

  autocomple_origin_mult(index: number, item: any) {
    item.input_origin = !item.input_origin
    if (item.input_origin) {
      setTimeout(() => {
        const input_origin = document.getElementById("origin_address_" + index) as HTMLInputElement;
        const autocomplete_ori = new google.maps.places.Autocomplete(input_origin, this.options_autocomple);
        google.maps.event.addListener(autocomplete_ori, 'place_changed', () => {
          var place = autocomplete_ori.getPlace();
          item.origin = place.formatted_address
        })
      }, 500);
    }
  }

  autocomple_destination_mult(index: number, item: any) {
    item.input_destination = !item.input_destination
    if (item.input_destination) {
      setTimeout(() => {
        const input_origin = document.getElementById("destination_address_" + index) as HTMLInputElement;
        const autocomplete_ori = new google.maps.places.Autocomplete(input_origin, this.options_autocomple);
        google.maps.event.addListener(autocomplete_ori, 'place_changed', () => {
          var place = autocomplete_ori.getPlace();
          item.destination = place.formatted_address
        })
      }, 500);
    }
  }
  
}
