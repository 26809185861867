import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { AuthGuard } from '../shared/auth.guard';
import { AccountListComponent } from './account-list/account-list.component';

const routes: Routes = [
  {
    path: "account", component: DashboardComponent, canActivate: [AuthGuard], children: [{
      path: "", component: AccountListComponent, data: {module: "Cuentas de Usuarios", accion: "Listado"}
    }]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
