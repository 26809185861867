import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  api_url = environment.url;

  constructor(private http: HttpClient) { }

  addClient(data: any) {
    return this.http.post(`${this.api_url}client/add`, data);
  }

  updateClient(data: any, id: number) {
    return this.http.post(`${this.api_url}client/update/${id}`, data);
  }

  listClient() {
    return this.http.get(`${this.api_url}client/list`)
  }
  getId(id: number) {
    return this.http.get(`${this.api_url}client/show/${id}`)
  }

  getPostal(code: any) {
    return this.http.get(`${this.api_url}code_postal/${code}`)
  }

  getInfo() {
    return this.http.get(`${this.api_url}client/getCombo`)
  }
  deleteClient(id: any) {
    return this.http.get(`${this.api_url}client/delete/${id}`)
  }
}
