import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DriverAddComponent } from './driver-add/driver-add.component';
import { DriverEditComponent } from './driver-edit/driver-edit.component';
import { DriverListComponent } from './driver-list/driver-list.component';
import { DriverRoutingModule } from './driver-routing.module';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AgGridModule} from 'ag-grid-angular';
import {NgxSpinnerModule} from 'ngx-spinner'



@NgModule({
  declarations: [
    DriverAddComponent,
    DriverEditComponent,
    DriverListComponent
  ],
  imports: [
    CommonModule,
    DriverRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AgGridModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DriverModule { }
