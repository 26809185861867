import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AssistantService } from 'src/app/services/assistant.service';
import { UtilsService } from 'src/app/services/utils.service';

declare const $: any;

@Component({
    selector: 'app-assistant-edit',
    templateUrl: './assistant-edit.component.html',
    styleUrls: ['./assistant-edit.component.css']
})
export class AssistantEditComponent {

    private idAssistant: number = 0;

    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private spinner: NgxSpinnerService,
        private apiAssistant: AssistantService,
        private route: ActivatedRoute
    ) {
        this.spinner.show()
        this.route.params.subscribe(params => {
            this.idAssistant = params["id"]
            this.getDataAssitant(params["id"])
        })
    }

    getDataAssitant(id: number) {
        this.apiAssistant.getId(id).subscribe((resp) => {
            this.formModelData = resp
            this.spinner.hide()
        })
    }

    formModelData: any = {
        name: null,
        last_name: null,
        cell_phone: null,
        birthday_date: null,
        municipality: null,
        bank_data: {
            holder_name: null,
            bank_name: null,
            account_number: null,
            account_type: null
        }
    }

    assistantForm!: FormGroup



    ngOnInit(): void {

        this.assistantForm = this.fb.group({
            name: ['', Validators.required],
            last_name: ['', Validators.required],
            cell_phone: ['', Validators.required],
            birthday_date: ['', Validators.required],
            municipality: ['', Validators.required],
            bank_holder_name: ['', Validators.required],
            bank_name: ['', Validators.required],
            bank_account_number: ['', Validators.required],
            bank_account_type: ['', Validators.required]
        })
    }

    updateAssistant() {
        if (this.assistantForm.valid) {
            this.spinner.show()
            this.apiAssistant.updateAssistant(this.formModelData, this.idAssistant).subscribe((resp: any) => {
                this.spinner.hide()
                this.getDataAssitant(this.idAssistant)
                this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })

            }, (error) => {
                this.spinner.hide()
                this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al actualizar conductor", icon: "error" })
            })
        } else {

            this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
        }
    }

    get registerFormControl() {
        return this.assistantForm.controls;
    }
}
