<div class="col-xl">
    <div class="card mb-4">
        <div class="card-body">
            <full-calendar [options]="calendarOptions">
                <ng-template #eventContent let-arg>
                    <b>{{ arg.event.extendedProps.is_end == '0' ? 'Hora Carga' : 'Hora Descarga' }}: {{ arg.event.extendedProps.is_end == '0' ? arg.event.extendedProps.charging_hour : arg.event.extendedProps.download_time}}
                    <br> Cliente: {{arg.event.title}}</b><br>
                </ng-template>
            </full-calendar>
        </div>
    </div>
</div>