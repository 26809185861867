import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-address-destination',
    templateUrl: './address-destination.component.html',
    styleUrls: ['./address-destination.component.css']
})
export class AddressDestinationComponent {
    constructor() { }

    ngOnInit(): void {
    }

    public cellValue!: any;

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.cellValue = params;

    }

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        // set value into cell again
        this.cellValue = "";
        return true;
    }

    openModal() {
        this.cellValue.parent.editButtonClick(this.cellValue.event_text == 'Destino' ? this.cellValue.data.destination_address : this.cellValue.data.origin_address, this.cellValue.event_text)
    }
}
