import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { OrderService } from 'src/app/services/order.service';

@Component({
    selector: 'app-status-select',
    templateUrl: './status-select.component.html',
    styleUrls: ['./status-select.component.css']
})
export class StatusSelectComponent implements ICellRendererAngularComp {
    constructor(
        private apiService: OrderService
    ) { }

    statusSelect: any = null
    evidence_1 = false
    evidence_2 = false
    dataUser : any = {}

    public cellValue!: any

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams): void {
        this.statusSelect = params.data.status_id
        
        this.cellValue = params;
        this.dataUser = this.cellValue.parent.dataUser
        this.evidence_1 = this.cellValue.data.status_id_one != null && this.cellValue.data.status_id_one > 0 ? true : false
        this.evidence_2 = this.cellValue.data.status_id_two != null && this.cellValue.data.status_id_two > 0 ? true : false

        this.dataCombo = this.cellValue.parent.data_combo.filter((resp:any) => {
            if(!this.evidence_1 && !this.evidence_2){
                if(resp.id == 1 || resp.id == 2 || resp.id == 3)
                    return resp
            }else if(this.evidence_1 && !this.evidence_2){
                if(resp.id == 1 || resp.id == 2 || resp.id == 3 || resp.id == 4)
                    return resp
            }else if(this.evidence_2){
                if(resp.id == 1 || resp.id == 2 || resp.id == 3 || resp.id == 4 || resp.id == 5 || resp.id==6)
                    return resp
            }
        })

        this.cellValue.parent.data_combo.filter((resp:any) => {
            if(this.cellValue.value == resp.id){
                this.status_text = resp.name
            }
        })
    }

    status_text:any = ""

    // gets called whenever the user gets the cell to refresh
    refresh(params: ICellRendererParams) {
        this.cellValue = "";
        return true;
    }

    dataCombo: any = []
    ngOnInit(): void {
    }
    chagedStatus() {
        this.cellValue.parent.clickStatusChanged(this.cellValue.data.id, this.dataCombo)
    }
    selectColor(id:number){
        let color = ""
        switch (id) {
            case 1:
                color = '#F53043'
                break
            case 2:
                color = "#FCA14E"
                break
            case 3:
                color = "#FB8B25"
                break
            case 4:
                color = "#57BFFE"
                break
            case 5:
                color = "#2F76E5"
                break
            case 6:
                color = "#44C150"
                break
            case 7 : 
                color = '#FF0000'
                break
            default:
                break;
        }
        return color
    }
}
