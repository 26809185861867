<form class="mb-3" [formGroup]="loginForm" (ngSubmit)="login()">
    <div class="mb-3">
        <label for="email" class="form-label">Usuario</label>
        <div class="input-group input-group-merge">
            <input type="text" class="form-control"
                [ngClass]="loginForm.controls['user'].errors  && !loginForm.controls['user'].pristine ? 'is-invalid' : ''"
                formControlName="user" [(ngModel)]="formData.user">
            <div class="invalid-feedback"
                *ngIf="loginForm.controls['user'].errors  && !loginForm.controls['user'].pristine">Ingrese
                el nombre de usuario</div>
        </div>
    </div>
    <div class="mb-3 form-password-toggle">
        <div class="d-flex justify-content-between">
            <label class="form-label" for="password">Contraseña</label>
        </div>
        <div class="input-group input-group-merge">
            <input type="password" class="form-control"
                [ngClass]="loginForm.controls['password'].errors  && !loginForm.controls['password'].pristine ? 'is-invalid' : ''"
                formControlName="password" [(ngModel)]="formData.password">
            <div class="invalid-feedback"
                *ngIf="loginForm.controls['password'].errors  && !loginForm.controls['password'].pristine">Ingrese
                la contraseña</div>
        </div>
    </div>
    <button class="btn btn-primary d-grid w-100" type="submit">Ingresar</button>
</form>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">Espere un momento</p>
</ngx-spinner>