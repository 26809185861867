import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

declare const $: any;

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent {
  public cellValue!: any
  agInit(params: ICellRendererParams): void {
    this.cellValue = params
  }
  ngOnInit() {
    

    
  }
}
