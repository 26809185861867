<div class="col-xl">
    <form [formGroup]="assistantForm" id="formDriver" (ngSubmit)="updateAssistant()">
        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Datos Personales</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Nombre*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.name" [ngClass]="assistantForm.controls['name'].errors  && !assistantForm.controls['name'].pristine ? 'is-invalid' : ''"
                                    formControlName="name">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['name'].errors  && !assistantForm.controls['name'].pristine">
                                    El campo nombre es obligatorio
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Apellido*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.last_name" [ngClass]="assistantForm.controls['last_name'].errors  && !assistantForm.controls['last_name'].pristine ? 'is-invalid' : ''"
                                formControlName="last_name">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['last_name'].errors  && !assistantForm.controls['last_name'].pristine">
                                    El campo apellido es obligatorio
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Celular*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.cell_phone" [ngClass]="assistantForm.controls['cell_phone'].errors  && !assistantForm.controls['cell_phone'].pristine ? 'is-invalid' : ''"
                                    formControlName="cell_phone">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['cell_phone'].errors  && !assistantForm.controls['cell_phone'].pristine">
                                    El campo celular es obligatorio
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-6 col-xs-12">
                            <label class="form-label" for="basic-default-company">Fecha Nacimiento*</label>
                            <div class="input-group input-group-merge">
                                <input type="date" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.birthday_date" [ngClass]="assistantForm.controls['birthday_date'].errors  && !assistantForm.controls['birthday_date'].pristine ? 'is-invalid' : ''"
                                    formControlName="birthday_date">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['birthday_date'].errors  && !assistantForm.controls['birthday_date'].pristine">
                                    El campo fecha de nacimiento es obligatorio
                                </div>
                            </div>
                            
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <label class="form-label" for="basic-default-company">Alcaldia o Municipio*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.municipality" [ngClass]="assistantForm.controls['municipality'].errors  && !assistantForm.controls['municipality'].pristine ? 'is-invalid' : ''"
                                    formControlName="municipality">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['municipality'].errors  && !assistantForm.controls['municipality'].pristine">
                                    El campo alcaldia o municipio es obligatorio
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-4">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h5 class="mb-0">Datos Bancarios</h5>
            </div>
            <div class="card-body">
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Nombre del Titular*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.bank_data.holder_name" [ngClass]="assistantForm.controls['bank_holder_name'].errors  && !assistantForm.controls['bank_holder_name'].pristine ? 'is-invalid' : ''"
                                    formControlName="bank_holder_name">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['bank_holder_name'].errors  && !assistantForm.controls['bank_holder_name'].pristine">
                                    El campo nombre del titular es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Banco*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.bank_data.bank_name" [ngClass]="assistantForm.controls['bank_name'].errors  && !assistantForm.controls['bank_name'].pristine ? 'is-invalid' : ''"
                                formControlName="bank_name">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['bank_name'].errors  && !assistantForm.controls['bank_name'].pristine">
                                    El campo nombre del titular es obligatorio
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-lg-4 col-xs-12">
                            <label class="form-label" for="basic-default-company">Numero de Cuenta o Tarjeta*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.bank_data.account_number" [ngClass]="assistantForm.controls['bank_account_number'].errors  && !assistantForm.controls['bank_account_number'].pristine ? 'is-invalid' : ''"
                                    formControlName="bank_account_number">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['bank_account_number'].errors  && !assistantForm.controls['bank_account_number'].pristine">
                                    El campo numero de cuenta o tareta es obligatorio
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <div class="row">
                        <div class="col-lg-12 col-xs-12">
                            <label class="form-label" for="basic-default-company">Tipo de Cuenta*</label>
                            <div class="input-group input-group-merge">
                                <input type="text" class="form-control" id="basic-default-company" [(ngModel)]="formModelData.bank_data.account_type" [ngClass]="assistantForm.controls['bank_account_type'].errors  && !assistantForm.controls['bank_account_type'].pristine ? 'is-invalid' : ''"
                                    formControlName="bank_account_type">
                                <div class="invalid-feedback" *ngIf="assistantForm.controls['bank_account_type'].errors  && !assistantForm.controls['bank_account_type'].pristine">
                                    Ingrese el nombre de cliente
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-4">
            <div class="card-body text-center">
                <button type="submit" class="btn btn-primary me-sm-3 me-1">Guardar Auxiliar</button>
            </div>
        </div>
    </form>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">Espere un momento...</p>
</ngx-spinner>