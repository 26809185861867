import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {  GridApi, RowGroupingDisplayType, StatusPanelDef } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';
import { AddressTooltipComponent } from 'src/app/cell-renderer/address-tooltip/address-tooltip.component';
import { BtnActionComponent } from 'src/app/cell-renderer/btn-action/btn-action.component';
import { GroupCellComponent } from 'src/app/cell-renderer/group-cell/group-cell.component';
import { LoadingGridComponent } from 'src/app/cell-renderer/loading-grid/loading-grid.component';
import { ModalEvidenceComponent } from 'src/app/cell-renderer/modal-evidence/modal-evidence.component';
import { StatusSelectComponent } from 'src/app/cell-renderer/status-select/status-select.component';
import { ClientService } from 'src/app/services/client.service';
import { OrderService } from 'src/app/services/order.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

declare const $: any;

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.css'],
  animations: [
    trigger('fade', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible <=> hidden', animate('.3s ease-in-out')),
    ]),
  ]
})
export class OrderListComponent {
  constructor(
    private apiService: OrderService,
    private spinner: NgxSpinnerService,
    private utils: UtilsService,
    private apiClient: ClientService,
    private router:Router
  ) {
  }

  address: any = []

  text_loading = "Cargando Información"
  data_combo: any = []
  dataCombo: any = []
  isVisible = true;

  evidence_1 = false
  evidence_2 = false

  files: File[] = [];
  url_page = environment.url_page
  public tooltipShowDelay = 0;
  public tooltipHideDelay = 10000;
  dataUser: any = {}

  public groupDisplayType: RowGroupingDisplayType = "groupRows";
  public groupRowRendererParams: any = {
    innerRenderer: GroupCellComponent
  }

  columnDefs = [
    { field: 'client', headerName: "Cliente", cellClass: 'textEllipsis' },
    { field: 'unified_concat', headerName: "Unidad", cellRenderer: GroupCellComponent},
    { field: 'date_start', headerName: "Fecha y hora de carga", width: 120 },
    { field: 'origin_address', headerName: "Origen", width: 200, cellClass: 'textEllipsis', tooltipField: 'origin_address', tooltipComponentParams: { color: '#ececec' } },
    { field: 'date_end', headerName: "Fecha y hora de descarga", width: 120, },
    { field: 'destination_address', headerName: "Destino", width: 200, cellClass: 'textEllipsis', tooltipField: 'destination_address', tooltipComponentParams: { color: '#ececec' } },
    {
      field: 'status_id', headerName: "Estado", cellClass: ((params: any) => { return this.selectColor(params.value) }),
      cellRenderer: StatusSelectComponent,
      cellRendererParams: { parent: this, data_combo: this.data_combo, dataUser: this.dataUser }
    },
    // { field: '', width: 80, headerName: "Acciones", cellRenderer: BtnActionComponent, cellRendererParams: { parent: this, dataUser: this.dataUser } },
    // { field: '', width: 80, headerName: "Evidencias", suppressRowTransform: true, cellStyle: { textAlign: 'center', overflow: 'visible' }, cellRenderer: ModalEvidenceComponent, cellRendererParams: { parent: this, dataUser: this.dataUser } }
  ];

  public loadingCellRenderer: any = LoadingGridComponent;

  public loadingCellRendererParams: any = {
    loadingMessage: 'Espere un momento...',
  };
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'center' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
      ]
    };

  public defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    tooltipComponent: AddressTooltipComponent,
    resizable: true,
    // wrapText: true,
    // autoHeight: true,
  };

  private gridApi!: GridApi
  service_id: any
  status_id: any

  public rowData: any = []
  public conut: any = [{
    pending: 0,
    in_route: 0,
    good: 0
  }]

  text_type: any = ""
  evidences: any = []
  logs: any = []

  status: any = []

  dataClient: any = []

  formModel: any = {
    created_at: '',
    client: '',
    status: '',
    upload_date: '',
    download_date: ''
  }

  dataSelect: any = {}

  listServices: any = []


  ngOnInit(): void {
    const servicio1$ = this.apiClient.getInfo();
    const servicio2$ = this.apiService.status();
    forkJoin([servicio1$, servicio2$]).subscribe(([resp1, resp2]) => {
      this.data_combo = resp2
      this.dataClient = resp1
      this.spinner.hide()

      this.treeView()
    })

    this.dataUser = this.utils.getDataUser()

    $("#upload_date").datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: (date_text: any) => {
        $("#upload_date").trigger('change')
      },
    }).change((ev: any) => {
      this.formModel.upload_date = $('#upload_date').datepicker({ dateFormat: 'yy-mm-dd' }).val();
      this.filter()
    });

    $("#download_date").datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: (date_text: any) => {
        $("#download_date").trigger('change')
      }
    }).change((ev: any) => {
      this.formModel.download_date = $('#download_date').datepicker({ dateFormat: 'yy-mm-dd' }).val();
      this.filter()
    });
  }

  removeDate(id: string) {
    $("#" + id).datepicker('setDate', null).trigger("change");
  }

  eventIsVisible() {
    this.isVisible = !this.isVisible
    if (this.isVisible) {

    } else {
      this.formModel.created_at = ''
      this.formModel.client = ''
      this.formModel.status = ''
      this.formModel.upload_date = ''
      this.formModel.download_dat = ''
      this.onGridReady({})
    }
  }

  event_text = ''

  filter() {
    this.spinner.show()
    this.apiService.filter(this.formModel).subscribe((resp: any) => {
      this.rowData = resp.data
      this.conut = resp.count
      this.spinner.hide()
    })
  }

  treeView() {
    let basicTree = $('#jstree-basic')
    let theme = $('html').hasClass('light-style') ? 'default' : 'default-dark'

    setTimeout(() => {
      basicTree.jstree({
        core: {
          themes: {
            name: theme
          }
        }
      });
    }, 1);
  }

  onSelectClient() {
    this.filter()
  }
  onSelectStatus() {
    this.filter()
  }

  onGridReady(params: any) {
    this.spinner.show()
    this.apiService.listService().subscribe((resp: any) => {
      this.rowData = resp.data;
      this.conut = resp.count;
      this.spinner.hide()
    })
  }
  actionDelete(id: number) {
    alert("click")
  }
  changedStatus() {
    let status_id = $('input[name="status_option"]:checked').val()

    if(!status_id){
      this.utils.alertSwal({ text: "Seleccione el estado que desea actualizar.", title: "Advertencia.", icon: "warning" });
      return
    }

    $("#modalStatus").modal("hide")
    this.utils.alertSwalConfir({ text: "Esta seguro que desea actualizar el estado el servicio", title: "Actualización de estado", icon: "warning", btn_confirm: "Aceptar" }).then((resp) => {
      if (resp.isConfirmed) {
        this.spinner.show()
        this.apiService.updateStatus(this.service_id, status_id, this.dataUser.id).subscribe((response) => {
          this.utils.alertSwal({ text: "Se ha realizado el cambio de estado de forma exitosa.", title: "Actualización.", icon: "success" });
          this.spinner.hide()
          this.onGridReady({})

        })
      } else {
        this.spinner.hide()
        this.onGridReady({})
      }
    })
  }

  modalActiveEvidence(id: any, status: any) {
    $("#modalEvidenceOption").modal("hide")
    this.status_id = status
    this.service_id = id
    $("#modalEvidence").modal("show")
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadEvidences() {
    $("#modalEvidence").modal("hide")
    this.spinner.show()
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    this.apiService.uploadFile(formData, this.service_id, this.status_id, this.dataUser.id).subscribe((resp) => {
      this.spinner.hide()
      this.files = []
      this.utils.alertSwal({ title: "Evidencias", text: "Se han cargado las evidencias de forma exitosa", icon: "success" })
      this.onGridReady({})
    }, (error) => {
      this.spinner.hide()
      this.utils.alertSwal({ title: "Evidencias", text: "Se han presentado un error en el cargue de las evidencias", icon: "error" })
    })
  }
  dowloandService(id: any) {
    let url = environment.url_page
    window.open(url + '/generate-pdf/' + id, "_blank")
  }

  modalOpenEvidence(id: number, status_id: any) {
    $("#modalEvidenceOption").modal("hide")
    this.text_type = status_id == 3 ? "Carga" : "Descarga"
    this.spinner.show()
    this.apiService.getEvidences({ orden_id: id, status_id: status_id }).subscribe((resp) => {
      this.spinner.hide()
      this.evidences = resp
      $("#modalEvidenceShow").modal("show")
    }, (error) => {
      this.spinner.hide()
    })
  }
  cancelOrder(id: number) {

    this.utils.alertSwalConfir({ text: "Esta seguro que desea cancelar el servicio", title: "Cancelación del servicio", icon: "warning", btn_confirm: "Aceptar" }).then((resp) => {
      if (resp.isConfirmed) {
        this.spinner.show()
        this.apiService.cancelOrder(id, this.dataUser.id).subscribe((response) => {
          this.utils.alertSwal({ text: "Se ha realizado la cancelación del servicio de forma exitosa.", title: "Cancelacion del servicio.", icon: "success" });
          this.spinner.hide()
          this.onGridReady({})
        })
      } else {
        this.spinner.hide()
        this.onGridReady({})
      }
    })
  }

  logsService(data: any) {

    this.logs = data
    $("#modalLogs").modal("show")
  }

  updateObservation(data: any) {
    this.dataSelect = data
    $("#modalObservation").modal("show")
  }

  updateObservationSend() {
    $("#modalObservation").modal("hide")
    this.spinner.show()

    this.apiService.observation({ observation: this.dataSelect.observation }, this.dataSelect.id).subscribe((resp) => {
      this.utils.alertSwal({ text: "Se actualizado la observacion de forma exitosa.", title: "Observación del servicio.", icon: "success" });
      this.spinner.hide()
      this.onGridReady({})
    }, (error) => {
      this.spinner.hide();
      this.utils.alertSwal({ title: "Observación ", text: "Se han presentado un error al actualizar la observacion", icon: "error" })
    })
  }
  clickStatusChanged(idOrder: number, combo_data: any) {
    this.dataCombo = combo_data
    this.service_id = idOrder
    $("#modalStatus").modal("show")
    $('input[name="status_option"]').prop("checked", false)
  }

  selectColor(id: number) {
    let color = ""
    switch (id) {
      case 1:
        color = 'color_1'
        break
      case 2:
        color = "color_2"
        break
      case 3:
        color = "color_3"
        break
      case 4:
        color = "color_4"
        break
      case 5:
        color = "color_5"
        break
      case 6:
        color = "color_6"
        break
      case 7:
        color = 'color_7'
        break
      default:
        break;
    }
    return color
  }

  openModalChangedUnit(id: number, units: any) {
    this.service_id = id
    $("#modalChangedUnit").modal("show")
  }
  updateOrderUnit() {

  }
  openModalEvidenceOption(id: number, status_id: number, evidence_1: boolean, evidence_2: boolean) {
    this.service_id = id
    this.status_id = status_id
    this.evidence_1 = evidence_1
    this.evidence_2 = evidence_2
    $("#modalEvidenceOption").modal("show")
  }
  onCellClicked(event:any){
    if(event.colDef.field != 'status_id')
      this.router.navigateByUrl('/service/detail', { state: event.data });
  }
}
