import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { AuthGuard } from '../shared/auth.guard';

const routes: Routes = [{
    path: "client", component: DashboardComponent, canActivate: [AuthGuard], children: [{
        path: "list", component: ClientListComponent, data: { module: "Cliente", accion: "Listado de Clientes" }
    }, {
        path: "add", component: ClientAddComponent, data: { module: "Cliente", accion: "Creación de Cliente" }
    }, {
        path: "update/:id", component: ClientEditComponent, data: { module: "Cliente", accion: "Actualización de Cliente" }
    }]
}];
@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientRoutingModule { }
