import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UnitService } from 'src/app/services/unit.service';
import { UtilsService } from 'src/app/services/utils.service';
declare const $: any;

@Component({
    selector: 'app-unit-edit',
    templateUrl: './unit-edit.component.html',
    styleUrls: ['./unit-edit.component.css']
})
export class UnitEditComponent {
    private idUnit: number = 0;

    constructor(
        private fb: FormBuilder,
        private utils: UtilsService,
        private spinner: NgxSpinnerService,
        private apiUnit: UnitService,
        private route: ActivatedRoute
    ) {
        this.spinner.show()
        this.route.params.subscribe(params => {
            this.idUnit = params["id"]
            this.getDataUnit(params["id"])
        })
    }


    getDataUnit(id: number) {
        this.apiUnit.getId(id).subscribe((resp) => {
            this.formModelData = resp;
            this.spinner.hide()
        })
    }

    formModelData: any = {
        unit: null,
        plates: null
    }

    unitForm!: FormGroup

    ngOnInit(): void {

        this.unitForm = this.fb.group({
            unit: ['', Validators.required],
            plates: ['', Validators.required]
        })
    }

    updateUnit() {
        if (this.unitForm.valid) {
            this.spinner.show()
            this.apiUnit.addUnit(this.formModelData).subscribe((resp: any) => {
                this.spinner.hide()
                this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
                this.getDataUnit(this.idUnit)
            }, (error) => {
                this.spinner.hide()
                this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al actualizar la unidad", icon: "error" })
            })
        } else {

            this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
        }
    }

    get registerFormControl() {
        return this.unitForm.controls;
    }
}
