import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AssistantService {

    api_url = environment.url;

    constructor(private http: HttpClient) { }

    addAssistant(data: any) {
        return this.http.post(`${this.api_url}assistant/add`, data);
    }

    updateAssistant(data: any, id: number) {
        return this.http.post(`${this.api_url}assistant/update/${id}`, data);
    }

    listAssistant() {
        return this.http.get(`${this.api_url}assistant/list`)
    }
    getId(id: number) {
        return this.http.get(`${this.api_url}assistant/show/${id}`)
    }

    getInfo() {
        return this.http.get(`${this.api_url}assistant/getCombo`)
    }
    deleteAssistant(id: any) {
        return this.http.get(`${this.api_url}assistant/delete/${id}`)
      }
}
