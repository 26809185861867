
<a href="javascript:void(0);" id="_top{{cellValue.data.id}}" (click)="dowloandService()" data-toggle="tooltip" data-placement="top" title="Descargar pdf" style="cursor: pointer;">
    <i class="ti ti-file-download"></i>
</a>
<a href="javascript:void(0);" id="_top{{cellValue.data.id}}" (click)="deleteEvent()" data-toggle="tooltip" data-placement="top" title="Cancelar servicio" style="cursor: pointer; margin-left: 8px;" *ngIf="dataUser.type_admin == 1 || dataUser.type_admin == 2">
    <i class="ti ti-trash"></i>
</a>
<a href="javascript:void(0);" id="_top{{cellValue.data.id}}" (click)="logEvent()" data-toggle="tooltip" data-placement="top" title="Ver historial" style="cursor: pointer; margin-left: 8px;" *ngIf="dataUser.type_admin == 1 || dataUser.type_admin == 2">
    <i class="ti ti-notes"></i>
</a>
<a href="javascript:void(0);" id="_top{{cellValue.data.id}}" data-toggle="tooltip" data-placement="top" title="Observacion: {{!cellValue.data.observation ? 'Ninguna' : cellValue.data.observation }}" (click)="updateObservation()" style="cursor: pointer; margin-left: 8px;" *ngIf="dataUser.type_admin == 1 || dataUser.type_admin == 2 || dataUser.type_admin == 3">
    <i class="ti ti-message"></i>
</a>
<a href="javascript:void(0);" id="_top{{cellValue.data.id}}" data-toggle="tooltip" data-placement="top" title="Cambiar unidad" (click)="openModalUnit(cellValue.data.id)" style="cursor: pointer; margin-left: 8px;" *ngIf="dataUser.type_admin == 1 || dataUser.type_admin == 2 || dataUser.type_admin == 3">
    <i class="ti ti-truck"></i>
</a>
