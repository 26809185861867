import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  constructor(private route: ActivatedRoute, private router: Router, public utils: UtilsService) {
    route.url.subscribe(() => {
      this.dataChildren = route?.snapshot?.firstChild?.data
    });
  }

  urlFiles = environment.url_page

  dataChildren: any = {}
  dataUser: any = {}

  ngOnInit(): void {
    this.dataUser = this.utils.getDataUser()
  }

  logout(){
    localStorage.removeItem("token")
    localStorage.removeItem("dataUser")
    this.router.navigate([ '/login' ]);
  }
}
