import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import { ComponentModule } from './component/component.module';
import { AppRoutingModule } from './app-routing.module';
import { ClientModule } from './client/client.module';
import { DriverModule } from './driver/driver.module';
import { AssistantModule } from './assistant/assistant.module';
import { UnitModule } from './unit/unit.module';
import { AddressDestinationComponent } from './cell-renderer/address-destination/address-destination.component';
import { AddressOriginComponent } from './cell-renderer/address-origin/address-origin.component';
import { ActionsComponent } from './cell-renderer/actions/actions.component';
import { LoadingGridComponent } from './cell-renderer/loading-grid/loading-grid.component';
import { OrderModule } from './order/order.module';
import { StatusSelectComponent } from './cell-renderer/status-select/status-select.component';
import { ModalEvidenceComponent } from './cell-renderer/modal-evidence/modal-evidence.component';
import { FormsModule } from '@angular/forms';
import { AddressTooltipComponent } from './cell-renderer/address-tooltip/address-tooltip.component';
import { BtnActionComponent } from './cell-renderer/btn-action/btn-action.component';
import { AccountModule } from './account/account.module';
import { ActionUserComponent } from './cell-renderer/action-user/action-user.component';
import { FilterServicesComponent } from './cell-renderer/filter-services/filter-services.component';
import { TreeComponent } from './cell-renderer/tree/tree.component';
import { GroupCellComponent } from './cell-renderer/group-cell/group-cell.component';

@NgModule({
  declarations: [
    AppComponent,
    AddressDestinationComponent,
    AddressOriginComponent,
    ActionsComponent,
    LoadingGridComponent,
    StatusSelectComponent,
    ModalEvidenceComponent,
    AddressTooltipComponent,
    BtnActionComponent,
    ActionUserComponent,
    FilterServicesComponent,
    TreeComponent,
    GroupCellComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ComponentModule,
    ClientModule,
    DriverModule,
    AssistantModule,
    UnitModule,
    OrderModule,
    FormsModule,
    AccountModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
