import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'src/app/account.service';
import { ActionUserComponent } from 'src/app/cell-renderer/action-user/action-user.component';
import { LoadingGridComponent } from 'src/app/cell-renderer/loading-grid/loading-grid.component';
import { UtilsService } from 'src/app/services/utils.service';

declare const $:any

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent {


  constructor(
    private api: AccountService,
    private spinner: NgxSpinnerService,
    private utils: UtilsService,
    private fb: FormBuilder
  ) { }

  text_loading = "Cargando Información"

  accountForm!: FormGroup

  formModelData = {
    name: null,
    user: null,
    email: null,
    type_admin: null,
    status: 0,
    password: null
  }

  ngOnInit(): void {

    this.accountForm = this.fb.group({
      name: ['', Validators.required],
      user: ['', Validators.required],
      email: ['', Validators.required],
      type_admin: ['', Validators.required],
      password: ['', Validators.required],
    })
  }

  public rowData: any = [];
  columnDefs = [
    { field: 'name', headerName: "Nombre" },
    { field: 'user', headerName: "Usuario" },
    { field: 'email', headerName: "Correo Electronico", width: 200 },
    { field: 'type_admin', headerName: "Tipo de Usuario", width: 120, cellRenderer: ActionUserComponent, cellRendererParams: { parent: this, type: 0 } },
    { field: 'status', headerName: "Estado", width: 200, cellRenderer: ActionUserComponent, cellRendererParams: { parent: this, type: 1 }  },
    { field: 'id', headerName: "Accion", width: 200, cellRenderer: ActionUserComponent, cellRendererParams: { parent: this, type: 2 }  }
  ];

  idAccount:any = null

  public loadingCellRenderer: any = LoadingGridComponent;
  public loadingCellRendererParams: any = {
    loadingMessage: 'Espere un momento...',
  };

  public defaultColDef = {
    sortable: true,
    flex: 1,
    filter: true,
    resizable: true,
    // wrapText: true,
    // autoHeight: true,
  };

  initForm(){
    this.formModelData = {
      name: null,
      user: null,
      email: null,
      type_admin: null,
      status: 0,
      password: null
    }

    this.accountForm.get("name")?.setValidators([Validators.required])
    this.accountForm.get("email")?.setValidators([Validators.required])
    this.accountForm.get("user")?.setValidators([Validators.required])
    this.accountForm.get("type_admin")?.setValidators([Validators.required])
    this.accountForm.get("password")?.setValidators([Validators.required])
  }

  onGridReady(params: any) {
    this.spinner.show()
    this.api.listAccount().subscribe((resp: any) => {
      this.rowData = resp;
      this.spinner.hide()
    })
  }

  openModalAdd(){
    this.initForm()
    $("#modalAddAccount").modal("show")
  }
  openModalUpdate(id:any, data:any){
    this.idAccount = id
    this.accountForm.get("password")?.setValidators(null)
    this.formModelData = data
    $("#modalUpdateAccount").modal("show")
  }

  get registerFormControl() {
    return this.accountForm.controls;
  }

  updateAccount(){
    if (this.accountForm.valid) {
      this.spinner.show()
      this.api.updateAccount(this.formModelData, this.idAccount).subscribe((resp: any) => {
        this.spinner.hide()
        $("#formAccount")[0].reset()
        this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
        this.onGridReady({})
        $("#modalUpdateAccount").modal("hide")
        this.initForm()
      }, (error) => {
        this.spinner.hide()
        this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al insertar la cuenta", icon: "error" })
      })
    } else {
      this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
    }

    
  }

  addAccount() {
    if (this.accountForm.valid) {
      this.spinner.show()
      this.api.addAccount(this.formModelData).subscribe((resp: any) => {
        this.spinner.hide()
        $("#formAccount")[0].reset()
        this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
        this.onGridReady({})
        $("#modalAddAccount").modal("hide")
        this.initForm()
      }, (error) => {
        this.spinner.hide()
        this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al insertar la cuenta", icon: "error" })
      })
    } else {

      this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
    }
  }
  actionUpdatePassword(id:any){
    this.idAccount = id
    this.accountForm.get("name")?.setValidators(null)
    this.accountForm.get("email")?.setValidators(null)
    this.accountForm.get("user")?.setValidators(null)
    this.accountForm.get("type_admin")?.setValidators(null)
    $("#modalUpdatePassword").modal("show")
  }

  updatePassword(){
    let data = {
      password: this.formModelData.password
    }

    if (this.formModelData.password) {
      this.spinner.show()
      this.api.updatePassword(this.idAccount, data).subscribe((resp: any) => {
        this.spinner.hide()
        $("#formAccount")[0].reset()
        this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
        this.onGridReady({})
        $("#modalUpdatePassword").modal("hide")
        this.initForm()
      }, (error) => {
        this.spinner.hide()
        this.utils.alertSwal({ title: "Error!", text: "Se ha presentado un error al actualizar la contraseña", icon: "error" })
      })
    } else {
      this.utils.alertSwal({ title: "Advertencia", text: "Verifique que la información se encuentre completa.", icon: "warning" })
    }
  }
  changedStatus(id:any){
    if($("#"+id).prop('checked')) {
      this.formModelData.status = 1
    } else {
      this.formModelData.status = 0
    }
  }

  deleteAccount(id:any){
    this.utils.alertSwalConfir({text: "Esta seguro que desea eliminar la cuenta de usuario", title: "Advertencia", icon: "warning", btn_confirm: "Eliminar" }).then((resp) => {
      if(resp.isConfirmed){
        this.spinner.show()
        this.api.delete(id).subscribe((resp:any) => {
          this.spinner.hide()
          this.utils.alertSwal({ title: "Exitoso", text: resp.msg, icon: "success" })
          this.onGridReady({})
        }, (error) => {
          this.spinner.hide()
          this.utils.alertSwal({ title: "Advertencia", text: "Se ha presentado un error al eliminar la cuenta.", icon: "error" })
        })
      }
    })
  }
  files: any= []
  onSelect(event: any) {
    this.files = []
    this.files.push(...event.addedFiles)
  }
  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  modalUploadPhoto(id:any){
    this.idAccount = id
    $("#modalPhoto").modal("show")
  }

  uploadPhoto() {
    $("#modalPhoto").modal("hide")
    this.spinner.show()
    const formData = new FormData();
    formData.append("file", this.files[0]);
    this.api.uploadFile(formData, this.idAccount).subscribe((resp) => {
      this.spinner.hide()
      this.files = []
      this.utils.alertSwal({ title: "Fotografia", text: "Se ha cargado la fotografia de forma exitosa", icon: "success" })
      this.onGridReady({})
    }, (error) => {
      this.spinner.hide()
      this.utils.alertSwal({ title: "Fotografia", text: "Se han presentado un erro en el cargue de la fotografia", icon: "error" })
    })
  }
}
