import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-modal-evidence',
  templateUrl: './modal-evidence.component.html',
  styleUrls: ['./modal-evidence.component.css']
})
export class ModalEvidenceComponent {
  constructor() { }

  evidence_1 = false
  evidence_2 = false
  dataUser: any = {}

  ngOnInit(): void {
  }

  public cellValue!: any;

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    this.cellValue = params;
    this.dataUser = this.cellValue.parent.dataUser
    this.evidence_1 = this.cellValue.data.status_id_one != null && this.cellValue.data.status_id_one > 0 ? true : false
    this.evidence_2 = this.cellValue.data.status_id_two != null && this.cellValue.data.status_id_two > 0 ? true : false

  }
  refresh(params: ICellRendererParams) {
    this.cellValue = "";
    return true;
  }

  // openModal(){
  //   this.cellValue.parent.modalActiveEvidence(this.cellValue.data.id, this.cellValue.data.status_id)
  // }
  dowloandService(){
    this.cellValue.parent.dowloandService(this.cellValue.data.id)
  }
  openModal(){
    this.cellValue.parent.openModalEvidenceOption(this.cellValue.data.id, this.cellValue.data.status_id, this.evidence_1, this.evidence_2)
  }
}
