import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from 'src/app/services/order.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment';

declare const $: any;

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent {
  constructor(
    private location: Location,
    private utils: UtilsService,
    private apiService: OrderService,
    private spinner: NgxSpinnerService,
    private router:Router
  ) {
  }

  order: any = {}
  dataUser: any = {}
  evidence_1: boolean = false
  evidence_2: boolean = false
  type_text: any = ""
  evidences: any = []
  files: any = []
  allGetEvidences: any = []
  finishEvidence: boolean = false
  logs: any = []
  finishLog: boolean = false
  url_page = environment.url_page

  ngOnInit() {
    this.order = this.location.getState();
    this.dataUser = this.utils.getDataUser()

    this.evidence_1 = this.order.status_id_one != null && this.order.status_id_one > 0 ? true : false
    this.evidence_2 = this.order.status_id_two != null && this.order.status_id_two > 0 ? true : false

    this.allEvidences()
    this.logsService()
  }

  modalActiveEvidence() {
    $("#modalEvidence").modal("show")
  }
  allEvidences() {
    this.apiService.getAllEvidences({ orden_id: this.order.id }).subscribe((resp) => {
      this.allGetEvidences = resp
      this.finishEvidence = true
    }, (error) => {
      this.finishEvidence = true
    })
  }

  modalOpenEvidence(status_id: any) {
    this.type_text = status_id == 3 ? "Carga" : "Descarga"
    this.spinner.show()
    this.apiService.getEvidences({ orden_id: this.order.id, status_id: status_id }).subscribe((resp) => {
      this.spinner.hide()
      this.evidences = resp
      $("#modalEvidenceShow").modal("show")
    }, (error) => {
      this.spinner.hide()
    })
  }

  onSelect(event: any) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadEvidences() {
    $("#modalEvidence").modal("hide")
    this.spinner.show()
    const formData = new FormData();
    for (var i = 0; i < this.files.length; i++) {
      formData.append("file[]", this.files[i]);
    }
    this.apiService.uploadFile(formData, this.order.id, this.order.status_id, this.dataUser.id).subscribe((resp) => {
      this.spinner.hide()
      this.files = []
      this.utils.alertSwal({ title: "Evidencias", text: "Se han cargado las evidencias de forma exitosa", icon: "success" })
    }, (error) => {
      this.spinner.hide()
      this.utils.alertSwal({ title: "Evidencias", text: "Se han presentado un error en el cargue de las evidencias", icon: "error" })
    })
  }
  filterEvidences(status: number) {
    let ev = this.allGetEvidences.filter((resp: any) => {
      if (resp.status_id == status) {
        return resp
      }
    })
    return ev
  }
  logsService() {
    this.finishLog = true
    this.logs = this.order.logs
  }
  observationUpdate(event: any) {
    let val = event.target.value ? event.target.value : null
    if (val == this.order.observation) {
      this.utils.alertSwal({ title: "Notas", text: "No se produjo cambios para actualizar", icon: "info" })
    } else {
      this.updateObservationSend(val)
    }
  }
  updateObservationSend(value: any) {
    this.spinner.show()
    this.apiService.observation({ observation: value }, this.order.id).subscribe((resp) => {
      this.utils.alertSwal({ text: "Se actualizado la nota de forma exitosa.", title: "Notas del servicio.", icon: "success" });
      this.order.observation = value
      this.spinner.hide()
    }, (error) => {
      this.spinner.hide();
      this.utils.alertSwal({ title: "Observación ", text: "Se han presentado un error al actualizar la nota", icon: "error" })
    })
  }
  dowloandService() {
    let url = environment.url_page
    window.open(url + '/generate-pdf/' + this.order.id, "_blank")
  }

  cancelOrder() {

    this.utils.alertSwalConfir({ text: "Esta seguro que desea cancelar el servicio", title: "Cancelación del servicio", icon: "warning", btn_confirm: "Aceptar" }).then((resp) => {
      if (resp.isConfirmed) {
        this.spinner.show()
        this.apiService.cancelOrder(this.order.id, this.dataUser.id).subscribe((response) => {
          this.utils.alertSwal({ text: "Se ha realizado la cancelación del servicio de forma exitosa.", title: "Cancelacion del servicio.", icon: "success" });
          this.spinner.hide()
        })
      } else {
        this.spinner.hide()
      }
    })
  }

  changedUnit(){
    this.router.navigateByUrl('/service/changed-unit', { state: this.order });
  }
}
