import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  api_url = environment.url;

  constructor(private http: HttpClient) { }

  addService(data:any){
    return this.http.post(`${this.api_url}service/add`, data);
  }

  updateService(data:any, id:number){
    return this.http.post(`${this.api_url}service/update/${id}`, data);
  }

  updateUnit(data: any){
    return this.http.post(`${this.api_url}service/update-unit`, data);
  }

  listService(){
    return this.http.get(`${this.api_url}service/list`)
  }

  calendarService(){
    return this.http.get(`${this.api_url}service/calendar`)
  }
  getId(id:number){
    return this.http.get(`${this.api_url}service/show/${id}`)
  }

  status(){
    return this.http.get(`${this.api_url}status`)
  }
  uploadFile(data:any, id:any, status:any, user_id:any){
    return this.http.post(`${this.api_url}upload/evidences/${id}/${status}/${user_id}`, data);
  }

  updateStatus(id:any, status:any, user_id:any){
    return this.http.get(`${this.api_url}service/updateStatus/${id}/${status}/${user_id}`);
  }

  getEvidences(data:any){
    return this.http.post(`${this.api_url}service/evidences`, data);
  }
  getAllEvidences(data:any){
    return this.http.post(`${this.api_url}service/allEvidences`, data);
  }
  cancelOrder(id:number, user_id:any){
    return this.http.get(`${this.api_url}service/cancelOrder/${id}/${user_id}`);
  }
  filter(data:any){
    return this.http.post(`${this.api_url}service/filter`, data);
  }
  observation(data:any, id:number){
    return this.http.post(`${this.api_url}service/observation/${id}`, data);
  }
  validUnified(data:any){
    return this.http.post(`${this.api_url}service/validUnified`, data);
  }
}
