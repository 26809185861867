import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AssistantRoutingModule } from './assistant-routing.module';
import { AssistantAddComponent } from './assistant-add/assistant-add.component';
import { AssistantEditComponent } from './assistant-edit/assistant-edit.component';
import { AssistantListComponent } from './assistant-list/assistant-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgGridModule } from 'ag-grid-angular'

@NgModule({
  declarations: [
    AssistantAddComponent,
    AssistantEditComponent,
    AssistantListComponent
  ],
  imports: [
    CommonModule,
    AssistantRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    AgGridModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AssistantModule { }
