import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { AuthGuard } from '../shared/auth.guard';
import { DriverListComponent } from './driver-list/driver-list.component';
import { DriverAddComponent } from './driver-add/driver-add.component';
import { DriverEditComponent } from './driver-edit/driver-edit.component';

const routes: Routes = [{
    path: "driver", component: DashboardComponent, canActivate: [AuthGuard], children: [{
        path: "list", component: DriverListComponent, data: { module: "Conductor", accion: "Listado de Conductores" }
    }, {
        path: "add", component: DriverAddComponent, data: { module: "Conductor", accion: "Creación de Conductor" }
    }, {
        path: "update/:id", component: DriverEditComponent, data: { module: "Conductor", accion: "Actualización de Conductor" }
    }]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DriverRoutingModule { }
