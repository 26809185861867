import { Component } from '@angular/core';

@Component({
  selector: 'app-group-cell',
  templateUrl: './group-cell.component.html',
  styleUrls: ['./group-cell.component.css']
})
export class GroupCellComponent {
  public value:any = {}
  agInit(params: any): void {
    this.value = params.value
  }

}
