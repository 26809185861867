import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../component/layouts/dashboard/dashboard.component';
import { AssistantListComponent } from './assistant-list/assistant-list.component';
import { AssistantAddComponent } from './assistant-add/assistant-add.component';
import { AssistantEditComponent } from './assistant-edit/assistant-edit.component';
import { AuthGuard } from '../shared/auth.guard';

const routes: Routes = [{
  path: "assistant", component: DashboardComponent, canActivate: [AuthGuard], children: [{
      path: "list", component: AssistantListComponent, data: { module: "Auxiliar", accion: "Listado de Auxiliares" }
  }, {
      path: "add", component: AssistantAddComponent, data: { module: "Auxiliar", accion: "Creación de Auxiliar" }
  }, {
      path: "update/:id", component: AssistantEditComponent, data: { module: "Auxiliar", accion: "Actualización de Auxiliar" }
  }]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssistantRoutingModule { }
