import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilsService } from 'src/app/services/utils.service';
import { AuthService } from 'src/app/shared/auth.service';

@Component({
    selector: 'app-signin',
    templateUrl: './signin.component.html',
    styleUrls: ['./signin.component.css']
})
export class SigninComponent {

    constructor(private auth: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private fb: FormBuilder,
        public utils: UtilsService
    ) { }

    returnUrl = "";

    sliderRecovery: any;

    ngOnInit(): void {

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.loginForm = this.fb.group({
            user: ['', Validators.required],
            password: ['', Validators.required]
        })
    }

    formData: any = {
        user: null,
        password: null
    };

    loginForm!: FormGroup

    login() {
        if(!this.loginForm.valid){
            this.utils.alertSwal({title: "Advertencia", text: "Los campos son obligatorios", icon: "warning"})
        } else{
            this.spinner.show();
            this.auth.login(this.formData).subscribe((data: any) => {
                this.spinner.hide();
                localStorage.setItem("token", data.success.token.plainTextToken);
                localStorage.setItem("dataUser", JSON.stringify(data.data));
                this.router.navigate([this.returnUrl = "/" ? '/' : this.returnUrl]);
    
            }, (error) => {
                this.utils.alertSwal({title: "Advertencia", text: error.error, icon:"warning"});
                this.spinner.hide();
            })
        }
        
    }
}
