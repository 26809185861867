<div class="col-xl">
    <div class="card mb-4">
        <div class="card-header d-flex justify-content-between align-items-center">
            <a class="btn btn-primary" routerLink="/client/add"><i class="fas fa-regular fa-circle-plus add-address"></i>&nbsp;&nbsp;
                    Agregar Cliente</a>
        </div>
        <div class="card-body">
            <ag-grid-angular style="width: 100%; height: 60vh;" class="ag-theme-alpine" [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef" [rowData]="rowData" [animateRows]="true"
                [loadingCellRenderer]="loadingCellRenderer" [loadingCellRendererParams]="loadingCellRendererParams"
                (gridReady)="onGridReady($event)"></ag-grid-angular>
        </div>
    </div>
</div>

<div class="modal fade" id="AddressDestination" tabindex="1" aria-hidden="true" data-bs-backdrop="static"
    data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-simple modal-refer-and-earn">
        <div class="modal-content p-3 p-md-5">
            <div class="modal-body">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                <div class="text-center mb-4">
                    <h3 class="mb-2">Direcciones de {{event_text}}</h3>
                    <p class="text-muted text-center mb-5 w-75 m-auto">

                    </p>
                </div>

                <div class="accordion mt-3" id="accordionExample">

                    <div class="accordion" id="accordionExample">
                        <div class="card accordion-item" *ngFor="let item of address; let i = index">
                            <h2 class="accordion-header" id="heading{{i+1}}">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    attr.data-bs-target="#collapse{{i+1}}" aria-expanded="true" attr.aria-controls="collapse{{i+1}}">
                                    Direccion #{{i+1}}
                                </button>
                            </h2>
                            <div id="collapse{{i+1}}" class="accordion-collapse collapse {{i == 0 ? 'show':''}}" attr.aria-labelledby="heading{{i+1}}"
                                data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <code>
                                        <p class="address_info"><strong>Direccion: </strong> {{item.street + ' ' + item.number}}</p>
                                        <p class="address_info"><strong>Colinia: </strong> {{item.cologne}}</p>
                                        <p class="address_info"><strong>Codigo Postal: </strong> {{item.postal_code}}</p>
                                        <p class="address_info"><strong>Estado: </strong> {{item.stated}}</p>
                                        <p class="address_info"><strong>Alcaldia o Municipio: </strong> {{item.municipality}}</p>
                                    </code>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.7)" size="medium" color="#fff" type="ball-atom">
    <p style="font-size: 20px; color: white">Espere un momento...</p>
</ngx-spinner>