import { Component } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin],
    locale: "es",
    initialView: 'timeGridWeek',
    weekends: true,
    timeZone: 'UTC',
    themeSystem: 'bootstrap5',
    eventTextColor: "white",
    buttonText:{
      today:    'Hoy',
      month:    'Mes',
      week:     'Semana',
      day:      'Dia',
    },
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    dayMaxEvents: true,
    selectable: true,
    navLinks: true,
    selectMirror: true,
    events: [
    ],
    select: function (arg) {
    },
    eventClick: (arg:any) => {
      this.dowloandService(arg.event.id);
    }
  };
  constructor(private apiService: OrderService){
    apiService.calendarService().subscribe((resp:any) => {
      this.calendarOptions.events = resp
    })
  }

  dowloandService(id:any){
    let url = environment.url_page
    window.open(url+'generate-pdf/'+id, "_blank")
  }
}
